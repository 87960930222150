import React, { useCallback, useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import Icons from "../../../../Constants/Icons";
import Astrologertransactionlist from "../Usertransaction/Astrologertransactionlist";
import Header from "../../../../Components/Header/Header";
import Modal from "react-modal";
import Addnewtransaction from "../Addnewtransaction";
import {
  apiCAllAddTransaction,
  apiCallAstroTransaction,
  post_api_call_transaction_getAstrologerTransactionHistory,
} from "../../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import moment from "moment";
import EmptyData from "../../../../Components/EmptyData/EmptyData";

function Astrologertransaction() {
  const items = ["Today", "This week", "Last Week", "This Month", "All Time"];
  const [sortOrder, setSortOrder] = useState("asc");
  const statusDropdownItem = ["All", "In progress", "Failed", "Successful"];
  const [searchValue, setSearchValue] = useState("");
  const [doughtChart, setDoughtChart] = useState();
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [allTransacationHistoryData, setAllTransactionHistoryData] = useState(
    []
  );
  const [astrologerTransactionOverview, setAstrologerTransactionOverview] =
    useState();
  const [isModalOpen, setIsMenuOpen] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [dropdownSelected, setDropDownSelectedValue] = useState("By Status");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);


  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallAstroTransList();
    onApiCallOverview();
  }, [pageComments]);

  useEffect(()=>{
  },[])

  const onApiCallOverview = () => {
    try {
      dispatch(setLoadingTrue());
      apiCallAstroTransaction().then((response) => {
        setAstrologerTransactionOverview(response.data);
        const chartData = {
          labels: ["Total paid amount", "Total outstanding amount"],
          datasets: [
            {
              label: "",
              data: [
                response?.data?.totalPaidAmount,
                response?.data?.totalOutStandingAmount,
              ],
              backgroundColor: [Colors.darkBlue, Colors.darkGreen],
              borderColor: Colors.black, // Set the border color for each bar
              borderWidth: 1,
            },
          ],
        };
        setDoughtChart(chartData);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onApiCallAstroTransList = (selectedPage) => {
    const param = {
      offset: 100 * pageComments,
      limit: 100,
    };

    dispatch(setLoadingTrue());
    post_api_call_transaction_getAstrologerTransactionHistory(param)
      .then((response) => {
        setSearchValue("");
        setDropDownSelectedValue("By Status");

        setTransactionHistoryData(pageComments == 0 ? response?.data:(prevData) => [
          ...prevData,
          ...response?.data,
        ]);
        setAllTransactionHistoryData(pageComments == 0 ? response?.data: (prevData) => [
          ...prevData,
          ...response?.data,
        ]);

        setTotalPageCount(Math.ceil(response.totalTransactionsCount / 100));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const closeModal = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = () => {};

  const byStatusSort = (status) => {
    setSearchValue("");
    if (status === "All") {
      setTransactionHistoryData(allTransacationHistoryData); // Show all items
    } else {
      const statusMap = {
        Successful: "successful",
      };

      const filteredData = allTransacationHistoryData.filter(
        (astrologer) => astrologer.status === statusMap[status]
      );

      setTransactionHistoryData(filteredData);
    }
  };

  const sortByDuration = (property) => {
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const dateA = moment(a.dateAndTime);
        const dateB = moment(b.dateAndTime);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
      setTransactionHistoryData(sortedData);
    }
  };

  const sortByAMount = () => {
    const sortedData = [...transactionHistoryData];
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    sortedData.sort((a, b) => {
      if (newSortOrder === "asc") {
        return a.totalPaidAmount - b.totalPaidAmount;
      } else {
        return b.totalPaidAmount - a.totalPaidAmount;
      }
    });
    setTransactionHistoryData(sortedData);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = allTransacationHistoryData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setTransactionHistoryData(newData);
    } else {
      setTransactionHistoryData(allTransacationHistoryData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };
  const customStyles = {
    overlay: {
      overflow: "hidden",
      zIndex: 9999,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
      padding: "10px",
      margin: "auto",
      overflow: "hidden",
      maxWidth: "920px",
      width: "100%",
      border: "none",
      background: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  const handleAddTransaction = (data) => {
    console.log("datadatadata", data);

    let finalParma = {
      transactions: data,
    };

    dispatch(setLoadingTrue());
    apiCAllAddTransaction(finalParma)
      .then((response) => {
        setDropDownSelectedValue("By Status");
        if(pageComments == 0){
          onApiCallOverview();
          onApiCallAstroTransList();
        }else{
          setPageComments(0)
        }
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });

    setIsMenuOpen(false);
  };

  const handleFileInputChange = (file) => {
    // console.log("Selected File:", file);
  };

  const handleDiscard = () => {
    // Handle discard action if needed
    console.log("Discarding changes");
  };
  Modal.setAppElement("#root");
  useEffect(() => {
    Modal.setAppElement("#root"); // Set the app element when the component mounts
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isModalOpen]);

  useEffect(() => {
    // document.body.style.overflow = "hidden";
    // document.body.style.overflow = "unset";
    // document.body.style.overflow = "hidden";
  }, []);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPageCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Transaction/Astrologer Transaction"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="sectionDivStyle">
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "16px",
              backgroundColor: "#25252d",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 0rem 0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  gap: "1rem",
                }}
              >
                <div className="contentTitle24Weight40">Overview</div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={handleSelect}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total amount
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {astrologerTransactionOverview?.totalPaidAmount}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: "white",
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total outstanding amount
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "white",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                    className="title24"
                  >
                    ₹ {astrologerTransactionOverview?.totalOutStandingAmount}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              // width: "49.38rem",
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Total amount
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"Today"}
                    items={items}
                    onSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="lastWeekEndDiv"
                    style={{ fontWeight: "600", color: Colors.secondary }}
                  >
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    ₹ {astrologerTransactionOverview?.total}
                  </div>
                </div>
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                >
                  {doughtChart && (
                    <Doughnut
                      options={{
                        cutout: "75%",
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={doughtChart}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  rowGap: "20px",
                  marginLeft: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "baseline",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkGreen,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}>
                      Total paid amount
                    </span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {astrologerTransactionOverview?.totalPaidAmount}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    alignSelf: "stretch",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "0.7rem",
                      height: "0.7rem",
                      left: "17px",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    <span style={{ marginLeft: "15px" }}>
                      Total outstanding amount
                    </span>
                    <br />
                    <div
                      style={{ fontWeight: "400" }}
                      className="lastWeekEndDiv"
                    >
                      ₹ {astrologerTransactionOverview?.totalOutStandingAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionDivStyle">
          <div
            className="title24FontWeight400WHite"
            style={{
              flex: "1",
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center",
              display: "flex",
            }}
          >
            Transaction history
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            {/* <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={"By status"}
                items={statusDropdownItem}
                color="blue"
                onSelect={byStatusSort}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div> */}
            {/* <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={"All time"}
                items={items}
                onSelect={handleSelect}
              />
            </div> */}
            <div
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                width: "15.75rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5rem 0.5rem",
                gap: "0.5rem",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "1.5rem",
                  height: "1.5rem",
                  objectFit: "contain",
                }}
                alt=""
                src={Icons.ic_search}
              />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
            <div
              style={{
                cursor: "pointer",
                background: Colors.white,
                padding: "7px 10px 7px 10px",
                gap: "0.5rem",
                borderRadius: "30px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                setIsMenuOpen(true);
              }}
            >
              <img
                src={Icons.ic_add}
                style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
              />
              <div className="darkGreyFont16" style={{ color: Colors.black }}>
                Add Transaction
              </div>
            </div>
          </div>
        </div>

        <Astrologertransactionlist
          handleScroll={handleScroll}
          commentsContainerRef={commentsContainerRef}
          data={transactionHistoryData}
          transactionID={"Transaction ID"}
          Astrologer={"Astrologer"}
          dateTimeSort={() => sortByDuration("Date & Time")}
          Amount={"Amount"}
          Outstandingamount={"Outstanding amount"}
          dateTime={"Date & Time"}
          status={"Status"}
          amountSort={() => sortByAMount()}
          Note={"Note"}
        />
      </div>
      {isModalOpen && (
        <Modal
          style={customStyles}
          ariaHideApp={false}
          isOpen={isModalOpen}
          // onRequestClose={closeModal}
        >
          <div
            className="modal-main-container"
            style={{ position: "relative" }}
          >
            <Addnewtransaction
              closeModal={() => {
                document.body.style.overflow = "unset";
                setIsMenuOpen(false);
              }}
              onAddTransaction={handleAddTransaction}
              onDiscard={handleDiscard}
              onFileInputChange={handleFileInputChange}
            />
          </div>
        </Modal>
      )}

      {transactionHistoryData.length <= 0 && <EmptyData />}
      {/* {transactionHistoryData.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={totalPageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallAstroTransList}
          />
        </div>
      )} */}
    </div>
  );
}
export default Astrologertransaction;
