import React, { useEffect, useRef, useState } from "react";
import "./SessionDetailsStyle.css";
import Images from "../../../../Constants/Images";
import Header from "../../../../Components/Header/Header";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import { useLocation } from "react-router-dom";
import {
  post_api_live_session_getLiveCallList,
  post_api_live_session_getLiveCallWaitingList,
  post_api_live_session_getLiveStreamMessages,
} from "../../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
const data = ["1", "2", "3"];

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    padding: 0,
    margin: "auto",
    maxWidth: "70%",
    width: "70%",
    border: "none",
    background: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

let audioUrl =
  "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3";
let staticUrlVideo =
  "https://blr1.digitaloceanspaces.com/zigzekassets/uploads/banners/Skype_Video.mp4";
function SessionStatusDetails() {
  // 1 2 3 all status set in sessiondetails

  const commentsContainerRef = useRef(null);
  const earingContainerRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { streamId } = location.state;
  const [type, setType] = useState(1);
  const [pageComments, setPageComments] = useState(0);
  const [pageEarning, setPageEaring] = useState(0);
  const videoRef = useRef(null);
  const [waitingSeSsionInfoData, setWaitingSessionData] = useState();
  const [commentListData, setCommentsListData] = useState([]);
  const [waitingListDataArray, setWaitingListDataArray] = useState([]);
  const [commentListTotalCount, setCommentsTotalCount] = useState(0);
  const [waitingListTotalCount, setWaitingListTotalCount] = useState(0);
  const [waitingListPricePerMin, setWaitingListPricePerMIn] = useState(0);
  const [waitingListTotalIncome, setWaitingListTotalIncome] = useState(0);
  const [lastTimeAudio, setLastItemAudio] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioElmRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(staticUrlVideo);
  const [videoPlayPause, setVideoPlayPause] = useState(false);

  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);

  useEffect(() => {
    onApiCallLiveSessionList();
  }, []);

  useEffect(() => {
    onApiCallLiveEaringSessionList();
  }, [pageEarning]);

  useEffect(() => {
    onApiCallLiveSessionComments();
  }, [pageComments]);

  const onApiCallLiveEaringSessionList = () => {
    const data = {
      streamId: streamId,
    };
    const param = {
      offset: 20 * pageEarning,
      limit: 20,
    };
    post_api_live_session_getLiveCallList(param, data)
      .then((response) => {
        setWaitingListTotalIncome(response?.totalIncome);
        setWaitingListTotalCount(Math.ceil(response?.totalSessionsCount / 20));
        setWaitingListDataArray((prevData) => [
          ...prevData,
          ...response?.sessionHistoryDetails,
        ]);
        setWaitingListPricePerMIn(response?.pricePerMinute ?? 0);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallLiveSessionComments = () => {
    const data = {
      streamId: streamId,
    };

    const param = {
      offset: 100 * pageComments,
      limit: 100,
    };
    // dispatch(setLoadingTrue());
    post_api_live_session_getLiveStreamMessages(param, data)
      .then((response) => {
        setCommentsListData((prevData) => [...prevData, ...response?.messages]);
        setCommentsTotalCount(Math.ceil(response?.totalMessagesCount / 100));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onApiCallLiveSessionList = () => {
    const param = {
      streamId: streamId,
    };
    dispatch(setLoadingTrue());
    post_api_live_session_getLiveCallWaitingList(param)
      .then((response) => {
        setWaitingSessionData(response);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const renderEmpty = (item) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: item ? "1rem" : "0rem",
          }}
        >
          <img
            style={{
              borderRadius: "8px",
              width: item ? "18rem" : "7rem",
              objectFit: "cover",
            }}
            alt=""
            src={item ? item : Images.images_no_data}
          />
          <div
            style={{ fontWeight: "400", color: Colors.greyColor50 }}
            className="fontSize20"
          >
            No data
          </div>
        </div>
      </div>
    );
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (commentListTotalCount > pageComments)
        setPageComments(pageComments + 1);
    }
  };

  const handleWaitingListScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      earingContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (waitingListTotalCount > pageEarning) setPageEaring(pageEarning + 1);
    }
  };

  const playPauseToggle = () => {
    setLastItemAudio(audioUrl);
    if (currentAudio) {
      currentAudio.pause();
    }
    if (lastTimeAudio == audioUrl) {
      if (isPlaying) {
        if (lastTimeAudio == audioUrl) {
          currentAudio.pause();
          setIsPlaying(false);
          return;
        }
      } else {
        if (lastTimeAudio == audioUrl) {
          currentAudio.play();
          setIsPlaying(true);
          return;
        }
      }
    } else {
      const audio = new Audio(audioUrl);
      setCurrentAudio(audio);
      audio.play();
      setIsPlaying(true);
    }
  };

  useEffect(() => {
    if (currentAudio) {
      currentAudio.addEventListener("timeupdate", () => {
        setCurrentTime(currentAudio.currentTime);
      });
      if (currentAudio.duration != "NaN") {
        setDuration(currentAudio.duration);
      }

      currentAudio.addEventListener("ended", () => {
        setIsPlaying(false);
        setCurrentTime(0);
        setDuration(0);
      });
      return () => {
        currentAudio.removeEventListener("timeupdate", () => {});
        currentAudio.removeEventListener("ended", () => {});
        currentAudio.removeEventListener("play", () => {});
      };
    }
  }, [currentAudio, isPlaying, currentTime, duration]);

  const forwardTenSeconds = () => {
    if (currentAudio) {
      const newTime = Math.min(currentAudio.currentTime + 10, duration);
      currentAudio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const backwardTenSeconds = () => {
    if (currentAudio) {
      const newTime = Math.max(currentAudio.currentTime - 10, 0);
      currentAudio.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  function formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  }

  const playVideo = () => {
    document.body.style.overflow = "hidden";
    // setVideoUrl(videoUrl);
    // setIsModalOpen(true);
  };

  useEffect(() => {
    const handleVideoEnd = () => {
      setVideoPlayPause(false);
      setVideoProgress(0);
    };

    const handleTimeUpdate = () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        const currentProgress =
          (videoElement.currentTime / videoElement.duration) * 100;
        setVideoProgress(currentProgress);
        setCurrentVideoTime(videoElement.currentTime);
      }
    };

    const handleLoadedMetadata = () => {
      const videoElement = videoRef.current;
      if (videoElement) {
        setVideoDuration(videoElement.duration);
      }
    };

    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("ended", handleVideoEnd);
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      videoElement.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("ended", handleVideoEnd);
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
        videoElement.removeEventListener(
          "loadedmetadata",
          handleLoadedMetadata
        );
      }
    };
  }, []);

  const formatTimeVideo = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header
        name={
          "Sessions / Live sessions / SessionID#" +
          waitingSeSsionInfoData?.sessionInformationData?._id
        }
      />
      <div
        style={{
          display: "flex",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            flex: 0.2,
          }}
          className="details_main_section"
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              zIndex: "0",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  borderRadius: "87px",
                  backgroundColor: "#25252d",
                  width: "2.63rem",
                  height: "2.63rem",
                  overflow: "hidden",
                  flexShrink: "0",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    width: "2.75rem",
                    height: "3.13rem",
                    objectFit: "cover",
                    zIndex: "0",
                  }}
                  alt=""
                  src={
                    waitingSeSsionInfoData?.sessionInformationData
                      ?.astrologerImage
                  }
                />
              </div>
              <div className="details_border_parent_section">
                <div style={{ fontWeight: "500" }} className="fontSize18">
                  {
                    waitingSeSsionInfoData?.sessionInformationData
                      ?.astrologerName
                  }
                </div>
                <div
                  style={{
                    display: "flex",
                    fontSize: "0.8rem",
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                >
                  {waitingSeSsionInfoData?.sessionInformationData?.astrologerId}
                </div>
              </div>
            </div>
          </div>
          {type == 1 ? (
            <div style={{ flex: 1, alignItems: "center", display: "flex" }}>
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  width: "12rem",
                }}
                onClick={() => {
                  if (videoPlayPause) {
                    videoRef.current.pause();
                  } else {
                    videoRef.current.play();
                  }
                  setVideoPlayPause(!videoPlayPause);
                }}

                // onClick={() => playVideo()}
              >
                {/* {!videoPlayPause && (
                  <img
                    style={{
                      position: "absolute",
                      width: "2rem",
                      height: "2rem",
                      alignSelf: "center",
                      display: "flex",
                    }}
                    alt=""
                    src={Icons.ic_play_pause}
                  />
                )} */}
                <video
                  ref={videoRef}
                  src={videoUrl}
                  autoPlay={videoPlayPause}
                  controls
                  // controlsList=""
                  // loop
                  style={{
                    objectFit: "cover",
                    borderRadius: "10px",
                    width: "18.94rem",
                    height: "34.94rem",
                    touchAction: "none",
                  }}
                />
                {/* <div
                  style={{
                    bottom: 0,
                    backgroundColor: Colors.mainDarkColor,
                    width: "13.94rem",
                    // padding:"10px",
                    position: "absolute",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        color: "#fff",
                        width: "80%",
                       
                      }}
                    >
                      {formatTimeVideo(currentVideoTime)} /{" "}
                      {formatTimeVideo(videoDuration)}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        height: "0.5rem",
                        backgroundColor: "#e0e0e0",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          width: `${videoProgress}%`,
                          height: "0.5rem",
                          borderRadius: "5px",
                          backgroundColor: "#76c7c0",
                        }}
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              {/* <div
                style={{
                  width: "20rem",
                  height: "auto",
                  border: "0.7px solid grey",
                  borderRadius: "10px",
                  overflow: "hidden",
                  padding: 5,
                  marginBottom:'5rem'
                }}
              >
                <img
                  src={
                    isPlaying == true && lastTimeAudio == audioUrl
                      ? Images.image_wave_effect
                      : Icons.ic_wave_stop_without_back
                  }
                  style={{
                    width: "100%",
                    height: "2rem",
                    bottom: -5,
                    objectFit: "cover",
                  }}
                />
                <audio
                  src={audioUrl ?? ""}
                  ref={audioElmRef}
                  id={`audio-${audioUrl}`}
                />
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center", // Check if overridden elsewhere
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      onClick={() => backwardTenSeconds()}
                      src={Icons.ic_backwardseconds}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        height: "1.2rem",
                        width: "1.2rem",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      src={
                        isPlaying == true && lastTimeAudio == audioUrl
                          ? Icons.ic_audio_pause
                          : Icons.ic_play_pause
                      }
                      onClick={() => playPauseToggle(audioUrl)}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        height: "1.2rem",
                        width: "1.2rem",
                      }}
                    />
                  </div>
                  <div>
                    <img
                      onClick={() => forwardTenSeconds()}
                      src={Icons.ic_forwardseconds}
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        height: "1.2rem",
                        width: "1.2rem",
                      }}
                    />
                  </div>
                  {lastTimeAudio == audioUrl ? (
                    <div
                      style={{
                        fontSize: "0.8rem",
                        color: "#FFF",
                        fontWeight: "600",
                        position: "relative",
                      }}
                    >
                      {formatTime(currentTime)} /{" "}
                      {duration >= 0 ? formatTime(duration) : "00:00"}
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "0.8rem",
                        color: "#FFF",
                        fontWeight: "600",
                        position: "relative",
                      }}
                    >
                      {"00:00"} / {"00:00"}
                    </div>
                  )}
                </div>
              </div> */}
            </div>
          ) : (
            <div
              style={{
                borderRadius: "10px",
                background: Colors.tabBackgroundColor,
                width: "15rem",
                height: "34.94rem",
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  alignItems: "center",
                  color: Colors.secondary,
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "0.85rem ",
                }}
                className="contentTitle24Weight40"
              >
                {type == 3 && "Session completed"}
                {type == 4 && "Session missed"}

                {type == 2 && "Session starts at"}
                {type == 2 && (
                  <div
                    style={{ fontSize: "0.85rem " }}
                    className="contentTitle24Weight40"
                  >
                    19-07-2023, 09:30 AM
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            flex: 0.5,
          }}
          className="details_main_section"
        >
          <div
            style={{
              alignSelf: "stretch",
            }}
          >
            <div
              className="fontSize16"
              style={{
                alignSelf: "stretch",
                fontWeight: 400,
                position: "relative",
              }}
            >
              Comments
            </div>
          </div>
          {commentListData.length != 0 ? (
            <div
              ref={commentsContainerRef}
              onScroll={handleScroll}
              style={{ maxHeight: "34.94rem", overflowY: "auto" }}
              className="scrollable-container"
            >
              {commentListData.map((item, index) => {
                return (
                  <div
                    style={{
                      marginBottom: "0.5rem",
                      alignSelf: "stretch",
                      borderRadius: "8px",
                      backgroundColor:
                        item?.messageType == "gift"
                          ? "rgba(255, 255, 255, 0.1)"
                          : "transparent",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "0.25rem 0.5rem",
                      gap: "0.5rem",
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "2rem",
                        height: "2rem",
                      }}
                      alt=""
                      src={Icons.ic_emoji_default}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "0.65rem",
                          position: "relative",
                          fontFamily: "Poppins-Regular",
                          fontWeight: "500",
                          color: Colors.white,
                        }}
                      >
                        {item?.senderName}
                      </div>
                      {item?.messageType == "gift" ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              gap: "0.19rem",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "0.65rem",
                                position: "relative",
                                fontFamily: "Poppins-Regular",
                                fontWeight: "400",
                                color: Colors.white,
                              }}
                            >
                              Sent you
                            </div>
                            <img
                              style={{
                                position: "relative",
                                width: "0.85rem",
                                height: "0.85rem",
                                objectFit: "cover",
                              }}
                              alt=""
                              src={item?.giftImage}
                            />
                            <div
                              style={{
                                fontSize: "0.65rem",
                                position: "relative",
                                fontFamily: "Poppins-Regular",
                                fontWeight: "500",
                                color: Colors.white,
                              }}
                            >
                              {item?.giftName}
                            </div>
                          </div>
                          <div
                            style={{
                              borderRadius: "47px",
                              backgroundColor: "#12b76a",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "0.25rem 0.5rem",
                              fontSize: "0.63rem",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "0.65rem",
                                position: "relative",
                                fontFamily: "Poppins-Regular",
                                fontWeight: "600",
                                color: Colors.white,
                              }}
                            >
                              + ₹{item?.giftPrice}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            fontSize: "0.65rem",
                            position: "relative",
                            fontFamily: "Poppins-Regular",
                            fontWeight: "400",
                            color: Colors.white,
                          }}
                        >
                          {item?.message}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            renderEmpty(Images.ic_chat_empty)
          )}
        </div>

        <div className="details_third_section">
          <div
            style={{
              alignSelf: "stretch",
              borderRadius: "16px",
              backgroundColor: "#24242d",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "1rem",
              fontSize: "0.88rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <div className="details_third_parent_section">
              <div
                className="fontSize16"
                style={{
                  alignSelf: "stretch",
                  fontWeight: 400,
                  position: "relative",
                }}
              >
                Session info
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    fontSize: "0.8rem",
                    color: Colors.greyColor50,
                    fontFamily: "Poppins-Regular",
                  }}
                >
                  Session ID
                </div>
                <div
                  className="fontSize16"
                  style={{
                    alignSelf: "stretch",
                    fontWeight: 400,
                    position: "relative",
                  }}
                >
                  {waitingSeSsionInfoData?.sessionInformationData?._id}
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    fontSize: "0.8rem",
                    color: Colors.greyColor50,
                  }}
                >
                  Session topic
                </div>
                <div
                  className="fontSize16"
                  style={{
                    alignSelf: "stretch",
                    fontWeight: 400,
                    position: "relative",
                  }}
                >
                  {
                    waitingSeSsionInfoData?.sessionInformationData
                      ?.sessionSubject
                  }
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      fontSize: "0.8rem",
                      color: Colors.greyColor50,
                    }}
                  >
                    Session duration
                  </div>
                  <div
                    className="fontSize16"
                    style={{
                      alignSelf: "stretch",
                      fontWeight: 400,
                      position: "relative",
                    }}
                  >
                    1h 36m
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      fontSize: "0.8rem",
                      color: Colors.greyColor50,
                    }}
                  >
                    User watching
                  </div>
                  <div
                    className="fontSize16"
                    style={{
                      alignSelf: "stretch",
                      fontWeight: 400,
                      position: "relative",
                    }}
                  >
                    {
                      waitingSeSsionInfoData?.sessionInformationData
                        ?.userWatchingCount
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="details_main_container">
            <div className="details_third_parent_section">
              <div
                className="fontSize16"
                style={{
                  alignSelf: "stretch",
                  fontWeight: 400,
                  position: "relative",
                }}
              >
                Waiting list
              </div>
              {data.length != 0 && (
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                  }}
                >
                  <div className="details_total_wait_text">Total wait time</div>

                  <div
                    style={{
                      position: "relative",
                      fontSize: "0.8rem",
                      color: Colors.greyColor50,
                      fontFamily: "Poppins-Regular",
                    }}
                  >
                    {waitingSeSsionInfoData?.totalWaitingMinute}m
                  </div>
                </div>
              )}
            </div>

            {waitingSeSsionInfoData?.waitingListData?.length != 0 ? (
              <div className="details_border_section">
                <div
                  style={{ maxHeight: "10rem" }}
                  className="scrollable-container"
                >
                  {waitingSeSsionInfoData?.waitingListData.map(
                    (item, index) => {
                      return (
                        <div className="details_border_parent_section">
                          <div className="details_border_parent_in_section">
                            <div className="details_list_fourth_div">
                              <img
                                style={{
                                  position: "relative",
                                  width: "2rem",
                                  height: "2rem",
                                }}
                                alt=""
                                src={item?.userImage}
                              />
                              <div className="details_list_text">
                                {item?.userName}
                              </div>
                              <div
                                style={{
                                  position: "relative",
                                  textAlign: "center",
                                  fontSize: "0.65rem",
                                  fontFamily: "Poppins-Regular",
                                  fontWeight: "400",
                                  color: Colors.secondary,
                                }}
                              >
                                {item?.waitingTime}m
                              </div>
                            </div>
                          </div>
                          <div className="borderTopStyle" />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              renderEmpty()
            )}
          </div>
          <div className="details_main_container">
            <div className="details_third_parent_section">
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  flexShrink: 0,
                }}
              >
                <div
                  className="fontSize16"
                  style={{
                    fontWeight: 400,
                  }}
                >
                  Earnings in this session
                </div>
                <div
                  className="fontSize16WithoutFlex"
                  style={{
                    fontWeight: 400,
                    position: "relative",
                  }}
                >
                  ₹{waitingListPricePerMin}/min
                </div>
              </div>
              {waitingListDataArray.length != 0 && (
                <div
                  style={{
                    alignSelf: "stretch",
                    display: "flex",
                  }}
                >
                  <div className="details_total_wait_text">Total earnings</div>
                  <div
                    style={{
                      position: "relative",
                      fontSize: "0.8rem",
                      fontFamily: "Poppins-Regular",
                      color: "#12b76a",
                    }}
                  >
                    ₹{waitingListTotalIncome}
                  </div>
                </div>
              )}
            </div>
            {waitingListDataArray.length != 0 ? (
              <div className="details_border_section">
                <div
                  ref={earingContainerRef}
                  onScroll={handleWaitingListScroll}
                  style={{ maxHeight: "10rem" }}
                  className="scrollable-container"
                >
                  {waitingListDataArray.map((item, index) => {
                    return (
                      <div className="details_border_parent_section">
                        <div className="details_border_parent_in_section">
                          <div className="details_list_fourth_div">
                            <img
                              style={{
                                position: "relative",
                                width: "2rem",
                                height: "2rem",
                              }}
                              alt=""
                              src={item?.customerImage}
                            />
                            <div className="details_list_text">
                              {item?.customerName}
                            </div>
                            <div className="details_call_session">
                              Call session
                            </div>
                            <div className="details_call_rupis">
                              ₹{item?.totalSessionAmount}
                            </div>
                          </div>
                        </div>
                        <div className="borderTopStyle" />
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              renderEmpty()
            )}
          </div>
        </div>
      </div>

      <Modal
        style={customStyles}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => {
          document.body.style.overflow = "unset";
          setVideoUrl(videoUrl);
          setIsModalOpen(false);
        }}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            padding: 20,
            borderRadius: "10px",
            height: "80%",
            width: "70%",
          }}
        >
          <div style={{ height: "100%", width: "100%", borderRadius: "10px" }}>
            <video
              src={videoUrl}
              autoPlay
              controls
              style={{
                objectFit: "cover",
                width: "100%",
                borderRadius: "10px",
                height: "100%",
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SessionStatusDetails;
