export const CustomUploadExcelFile = (formData,token) => {
  const requestOptions = {
    method: 'post',
    body: formData,
    headers: {
      'Authorization': `Bearer ${token}`
    }
  };

  return fetch("https://test-api.zigzek.com/admin/api/v1/transaction/uploadTransactionFile", requestOptions)
    .then((res) => {
      if (!res.ok) {
        throw new Error('Network response was not ok');
      }
      return res.json();
    })
    .catch((error) => {
      throw new Error('There was an error uploading the profile picture: ' + error.message);
    });
};
