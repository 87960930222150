import React from "react";
import Icons from "../../Constants/Icons";
import SessionCustomStyle from "./SessionCustomStyle";
import StatusButton from "../../Components/StatusButton/StatusButton";
import Colors from "../../Constants/Colors";

function SessionCustom({
  isLast,
  data,
}) {
  const onClickIdCopy = (item) => {
    navigator.clipboard.writeText(item);
  };
  return (
    <div
      style={{
        ...SessionCustomStyle.container,
        borderBottomWidth: isLast ? 0 : 1,
      }}
    >
      <div style={SessionCustomStyle.transactionMainId}>
        <div
          style={{
            ...SessionCustomStyle.sessionDetails,
            position: "relative",
            wordBreak: "break-all",
          }}
        >
          {data?.sessionId}
        </div>
        <img
          style={{ cursor: "pointer", marginRight: 10 }}
          onClick={() => onClickIdCopy(data?.sessionId)}
          className="icon_copy"
          alt=""
          src={Icons.ic_copy}
        />
      </div>
      <div style={{ ...SessionCustomStyle.sessionDetails, width: "22%" }}>
        {data?.sessionType}
      </div>
      <div
        style={{
          ...SessionCustomStyle.sessionDetails,
          ...SessionCustomStyle.amount,
        }}
      >
        ₹{data?.totalAmount}
      </div>
      <div
        style={{
          ...SessionCustomStyle.sessionDetails,
          ...SessionCustomStyle.duration,
        }}
      >
        {data?.duration}
      </div>
      {/* <StatusButton
        style={{
          backgroundColor: "rgba(46, 144, 250, 0.2)",
          width: "20%",
          color: Colors.darkBlue,
          border: "0px",
        }}
      >
        {status}
      </StatusButton> */}

      <StatusButton
        style={{
          width:"20%",
          backgroundColor:
            data?.sessionStatus === "completed"
              ? Colors.mainGreen01
              : data?.sessionStatus === "cancel" ||
              data?.sessionStatus == "declined"
              ? Colors.mainRed01
              : data?.sessionStatus === "scheduled"
              ? Colors.mainBlue01
              : data?.sessionStatus === "missed"
              ? Colors.lightGrey01
              : Colors.mainOrange01,
          borderColor:
          data?.sessionStatus === "completed"
              ? Colors.darkGreen
              : data?.sessionStatus === "cancel" ||
              data?.sessionStatus == "declined"
              ? Colors.darkRed
              : data?.sessionStatus === "scheduled"
              ? Colors.darkBlue
              : data?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
          color:
          data?.sessionStatus === "completed"
              ? Colors.darkGreen
              : data?.sessionStatus === "cancel" ||
              data?.sessionStatus == "declined"
              ? Colors.darkRed
              : data?.sessionStatus === "scheduled"
              ? Colors.darkBlue
              : data?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
        }}
      >
        {data?.sessionStatus}
      </StatusButton>
    </div>
  );
}

export default SessionCustom;
