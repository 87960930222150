import React from "react";
import Icons from "../../../../../Constants/Icons";
import { useNavigate } from "react-router-dom";
import Colors from "../../../../../Constants/Colors";
import { useState } from "react";

function Rejectededlist({
  data,
  onClickOnMoveApproved,
  onClickDelete,
  handleScroll,
  commentsContainerRef,
}) {
  const onClickIdCopy = () => {
    navigator.clipboard.writeText("");
  };
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };

  const onClickMoveApproved = (item) => {
    onClickOnMoveApproved(item);
    closeMenu();
  };
  const onClickDeleteItem = (item) => {
    onClickDelete(item);
    closeMenu();
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };
  return (
    <div
      onScroll={handleScroll}
      //  style={{ alignSelf: "stretch" }}
      style={{ maxHeight: "500px" }}
      ref={commentsContainerRef}
      className="scrollable-container"
    >
      {data.map((item, index) => {
        return (
          <div
            style={{
              padding: "1rem",
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "20%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Poppins-Regular",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    color: "#fff",
                    position: "relative",
                  }}
                  className="fontSize18Weight400"
                >
                  {item.astrologerId}
                </div>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickIdCopy()}
                  className="icon_copy"
                  alt=""
                  src={Icons.ic_copy}
                />
              </div>
              <div
                style={{
                  width: "16%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={item.astrologerProfile ?? Icons.ic_default_user}
                  />
                </div>
                <div className="fontSize18Weight400">
                  {item.astrologerName}
                  <br />
                  {/* <span
                    style={{
                      color: "green",
                    }}
                  >
                    {item.Login}
                  </span> */}
                </div>
              </div>
              <div
                style={{
                  width: "22%",
                  fontSize: "0.88rem",
                  fontFamily: "Poppins-Regular",
                  color: Colors.white,
                  position: "relative",
                }}
                className="fontSize18Weight400"
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_sms}
                  />
                  {item.email}
                </div>
                <div
                  style={{
                    paddingTop: "0.5rem",
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                  className="fontSize18Weight400"
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_call}
                  />
                  {item.contact}
                </div>
              </div>
              <div
                style={{
                  width: "9%",
                }}
                className="fontSize18Weight400"
              >
                {item.experience}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.25rem",
                  fontSize: "0.75rem",
                  fontFamily: "Poppins-Regular",
                  width: "24%",
                  flexWrap: "wrap",
                }}
              >
                {item.expertises.map((item) => {
                  return (
                    <div
                      style={{
                        borderRadius: "46px",
                        border: "1px solid rgba(255, 255, 255, 0.1)",
                        textAlign: "center",
                        padding: "5px 12px",
                      }}
                      className="my-account"
                    >
                      {item.expertise}
                    </div>
                  );
                })}
              </div>
              <div
                style={{
                  width: "7%",
                }}
                className="fontSize18Weight400"
              >
                {item.gender}
              </div>
              <div
                onClick={() => toggleMenu(index)}
                style={{ cursor: "pointer", width: "2%", display: "flex" }}
              >
                <img
                  style={{ width: "1.2rem", objectFit: "cover" }}
                  src={Icons.ic_more}
                />
              </div>
              <div
                style={{
                  marginBottom: index === data.length - 1 ? "80px" : "auto",
                  right: "2rem",
                  top: index == 0 ? "0px" : "auto",
                }}
                className="custom-menu-popup-container"
              >
                {openMenuIndex === index && (
                  <div
                    style={{ width: "auto" }}
                    onMouseLeave={closeMenu}
                    className="custom-menu-popup"
                  >
                    <div
                      onClick={() => onClickMoveApproved(item)}
                      className="custom-menu-list"
                    >
                      Move to approved
                    </div>
                    <div
                      onClick={() => onClickDeleteItem(item)}
                      className="custom-menu-list"
                    >
                      Delete
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Rejectededlist;
