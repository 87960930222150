import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import SessionListRender from "./SessionListRender";

function SessionList({
  data,
  sessionId,
  user,
  astrologer,
  dateTime,
  sessionType,
  duration,
  fees,
  totalfees,
  status,
  onSortingList,
  commentsContainerRef,
  handleScroll
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: "1",
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
          gap: "0.5rem",
        }}
      >
        <div style={{ width: "27%" }} className="contentName">
          {sessionId}
        </div>
        <div style={{ width: "18%" }} className="contentName">
          {user}
        </div>
        <div style={{ width: "18%" }} className="contentName">
          {astrologer}
        </div>
        <div style={{ width: "13%" }} className="contentName">
          {dateTime}
        </div>
        <div style={{ width: "15%" }} className="contentName">
          {sessionType}
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {duration}
        </div>
        {/* <div style={{ width: "10%" }} className="contentName">
          {fees}
        </div> */}
        <div
          style={{
            width: "12%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // cursor: "pointer",
            justifyContent: "flex-start",
          }}
          // onClick={onSortingList}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {totalfees}
          </div>
          {/* <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
            }}
            alt=""
            src={Icons.ic_shorting}
          /> */}
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {status}
        </div>
        {/* <div style={{ width: "0px" }}></div> */}
      </div>
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >

      {data.map((session, index) => (
        <SessionListRender
          key={index}
          session={session}
        />
      ))}
      </div>
    </div>
    // </div>
  );
}

export default SessionList;
