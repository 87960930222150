import {
  addNewAstrologer,
  allAstrologerList,
  allUser,
  astrologerActiveInactive,
  astrologerDetail,
  astrologerGetAstrologerSessionList,
  astrologerGetAstrologerTransactionHistory,
  astrologerGetAstrologerWithdrawalsHistory,
  astrologerUserDetails,
  astrologer_getAstrologerLiveUpdates,
  bannerActiveInactiveBanner,
  bannerCreateNewBanner,
  bannerEditBanner,
  bannerGetAllBanners,
  changeOrderStatus,
  couponAddNewCoupon,
  couponCouponOverview,
  couponEditCoupon,
  couponGetCouponList,
  couponUpdateCouponStatus,
  deleteAstrologer,
  editAstrologerDetails,
  expertises,
  getAllOrderList,
  getAstrologerApproveOrReject,
  getNewAstrologerRegistration,
  getScheduleNotificationList,
  getTicketList,
  getUserQuickSessionInfo,
  getUserSessionList,
  getUserTransactionHistory,
  incomeByTransactionType,
  live_session_getLiveCallList,
  live_session_getLiveCallWaitingList,
  live_session_getLiveStreamMessages,
  live_session_live_session_list,
  loginAdmin,
  moveAstrologerToOnboard,
  notificationDeleteNotification,
  notificationEditNotification,
  notificationGetAdminNotificationList,
  notificationSendNotifications,
  product_addNewProduct,
  product_deleteProduct,
  product_editProduct,
  sendGiftToUser,
  sessionGetOverview,
  session_getSessionList,
  session_overview_details,
  setAstrologerRegisterStatus,
  shopGetAllShopCategories,
  shop_getInventoryOverview,
  shop_getProductDetails,
  shop_getProductList,
  shop_getProductOrderList,
  shop_mainOverview,
  supportAllMessage,
  transactionEarningTransactionHistory,
  transactionEarningTransactionOverview,
  transactionGetRevenue,
  transactionGetTaxAmount,
  transaction_addTransactions,
  transaction_astrologerTransactionOverview,
  transaction_getAstrologerList,
  transaction_getAstrologerTransactionHistory,
  transaction_overview,
  updateTicket,
  uploadastrologerimages,
  userDetails,
  userTransactionHistory,
  usersCheckUserExists,
} from "./ApiEndPoint";
import { baseApiCall } from "./BaseApiCall";

export const postLogin = (data) => {
  return baseApiCall({
    url: loginAdmin,
    method: "post",
    data,
  });
};

export const getAllUser = (data) => {
  return baseApiCall({
    url: `${allUser}?offset=${data.offset}&limit=${data.limit}`,
    method: "post",
    data,
  });
};

export const getAllAstrologerList = (data) => {
  return baseApiCall({
    url: `${allAstrologerList}?offset=${data.offset}&limit=${data.limit}`,
    method: "get",
    data,
  });
};

export const getAllAstrologerNewRegistration = (data) => {
  return baseApiCall({
    url: `${getNewAstrologerRegistration}?offset=${data.offset}&limit=${data.limit}`,
    method: "post",
    data,
  });
};
export const addNewAstrologerApiCall = (data) => {
  return baseApiCall({
    url: addNewAstrologer,
    method: "post",
    data,
  });
};
export const postMoveAstrologerToOnboard = (data) => {
  return baseApiCall({
    url: moveAstrologerToOnboard,
    method: "post",
    data,
  });
};

export const setAstrologerRegisterStatusApi = (data) => {
  return baseApiCall({
    url: setAstrologerRegisterStatus,
    method: "post",
    data,
  });
};
export const getExpertises = (data) => {
  return baseApiCall({
    url: expertises,
    method: "post",
    data,
  });
};

export const uploadImageAddAstrologer = (data) => {
  return baseApiCall({
    url: uploadastrologerimages,
    method: "post",
    data,
  });
};

export const postDeleteAstrologer = (data) => {
  return baseApiCall({
    url: deleteAstrologer,
    method: "post",
    data,
  });
};

export const postAstrologerDetails = (data) => {
  return baseApiCall({
    url: astrologerDetail,
    method: "post",
    data,
  });
};
export const postEditAstrologerDetails = (data) => {
  return baseApiCall({
    url: editAstrologerDetails,
    method: "post",
    data,
  });
};

export const getAstrologerApproveOrRejectApi = (param, data) => {
  return baseApiCall({
    url: `${getAstrologerApproveOrReject}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const getAllAstrologerListPagination = (data) => {
  return baseApiCall({
    url: `${allAstrologerList}?offset=${data.offset}&limit=${data.limit}`,
    method: "get",
  });
};

export const getAstrologerUserDetails = (data) => {
  return baseApiCall({
    url: astrologerUserDetails,
    method: "post",
    data,
  });
};

export const getUserDetails = (data) => {
  return baseApiCall({
    url: userDetails,
    method: "post",
    data,
  });
};

export const postSupportAllMessage = (data) => {
  return baseApiCall({
    url: supportAllMessage,
    method: "post",
    data,
  });
};
export const postUpdateTicket = (data) => {
  return baseApiCall({
    url: updateTicket,
    method: "post",
    data,
  });
};

export const apiGetTicketList = (data) => {
  return baseApiCall({
    url: `${getTicketList}?offset=${data.offset}&limit=${data.limit}`,
    method: "get",
  });
};

export const apiGetUserSessionList = (param, data) => {
  return baseApiCall({
    url: `${getUserSessionList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const apiGetUserQuickSessionInfo = (data) => {
  return baseApiCall({
    url: `${getUserQuickSessionInfo}`,
    // url: `${getUserSessionList}?offset=${data.offset}&limit=${data.limit}`,
    method: "post",
    data,
  });
};

export const apiGetUserTransactionHistory = (param,data) => {
  return baseApiCall({
    url: `${getUserTransactionHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const api_Get_Session_List = (param,data) => {
  return baseApiCall({
    url: `${session_getSessionList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const get_session_overview_details = (data) => {
  return baseApiCall({
    url: `${session_overview_details}`,
    method: "post",
    data,
  });
};

export const getApiAllOrderList = (data) => {
  return baseApiCall({
    url: `${getAllOrderList}?offset=${data.offset}&limit=${data.limit}`,
    method: "post",
    data,
  });
};

export const postChangeOrderStatus = (data) => {
  return baseApiCall({
    url: `${changeOrderStatus}`,
    method: "post",
    data,
  });
};

export const post_api_main_overview = (data) => {
  return baseApiCall({
    url: `${shop_mainOverview}`,
    method: "post",
    data,
  });
};

export const postSessionGetOverview = (data) => {
  return baseApiCall({
    url: sessionGetOverview,
    method: "post",
    data,
  });
};

export const post_transaction_overview = (data) => {
  return baseApiCall({
    url: transaction_overview,
    method: "post",
    data,
  });
};

export const post_incomeBy_TransactionType = (data) => {
  return baseApiCall({
    url: incomeByTransactionType,
    method: "post",
    data,
  });
};

export const post_api_live_session_getLiveCallWaitingList = (data) => {
  return baseApiCall({
    url: live_session_getLiveCallWaitingList,
    method: "post",
    data,
  });
};

export const post_userTransactionHistory = (param,data) => {
  return baseApiCall({
    url: `${userTransactionHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const post_api_live_session_getLiveStreamMessages= (param,data) => {
  return baseApiCall({
    url: `${live_session_getLiveStreamMessages}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const post_api_live_session_getLiveCallList= (param,data) => {
  return baseApiCall({
    url: `${live_session_getLiveCallList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const post_api_live_session_live_session_list = (param,data) => {
  return baseApiCall({
    url: `${live_session_live_session_list}?offset=${param.offset}&limit=${param.limit}&type=${param.type}`,
    method: "post",
    data,
  });
};



export const post_sendGiftToUser = (data) => {
  return baseApiCall({
    url: sendGiftToUser,
    method: "post",
    data,
  });
};

export const get_api_astrologer_getAstrologerLiveUpdates = (data) => {
  return baseApiCall({
    url: astrologer_getAstrologerLiveUpdates,
    method: "post",
    data,
  });
};

export const post_add_product = (data) => {
  return baseApiCall({
    url: product_addNewProduct,
    method: "post",
    data,
  });
};

export const post_delete_product_deleteProduct = (data) => {
  return baseApiCall({
    url: product_deleteProduct,
    method: "post",
    data,
  });
};

export const post_api_call_shop_getInventoryOverview = (data) => {
  return baseApiCall({
    url: shop_getInventoryOverview,
    method: "post",
    data,
  });
};



export const post_api_edit_product_inventory = (data) => {
  return baseApiCall({
    url: product_editProduct,
    method: "post",
    data,
  });
};

export const post_api_product_details = (data) => {
  return baseApiCall({
    url: shop_getProductDetails,
    method: "post",
    data,
  });
};

export const postAPiCallAddNewCOuponCOde = (data) => {
  return baseApiCall({
    url: couponAddNewCoupon,
    method: "post",
    data,
  });
};
export const postAPiCAllCouponCouponOverview = (data) => {
  return baseApiCall({
    url: couponCouponOverview,
    method: "post",
    data,
  });
};

export const apiCallAstroTransaction = (data) => {
  return baseApiCall({
    url: transaction_astrologerTransactionOverview,
    method: "post",
    data,
  });
};

export const apiCAllAddTransaction = (data) => {
  return baseApiCall({
    url: transaction_addTransactions,
    method: "post",
    data,
  });
};




export const post_api_shop_getProductList = (param,data) => {
  return baseApiCall({
    url: `${shop_getProductList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const post_api_shop_getProductOrderList = (param,data) => {
  return baseApiCall({
    url: `${shop_getProductOrderList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const post_api_call_transaction_getAstrologerTransactionHistory = (param,data) => {
  return baseApiCall({
    url: `${transaction_getAstrologerTransactionHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const postApiCallCOuponCodeList = (param,data) => {
  return baseApiCall({
    url: `${couponGetCouponList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};





export const postApiCallTransactionAstrologer = (param,data) => {
  return baseApiCall({
    url: `${transaction_getAstrologerList}?offset=${param.offset}&limit=${param.limit}&astrologerName=${param.astrologerName}`,
    method: "post",
    data,
  });
};

export const apiCallEarningTransactionHistory = (param,data) => {
  return baseApiCall({
    url: `${transactionEarningTransactionHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};



export const apiCallTransactionEarningOverview = (data) => {
  return baseApiCall({
    url: transactionEarningTransactionOverview,
    method: "post",
    data,
  });
};

export const apiCallCouponUpdateCouponStatus = (data) => {
  return baseApiCall({
    url: couponUpdateCouponStatus,
    method: "post",
    data,
  });
};

export const apiCallCouponEditCoupon = (data) => {
  return baseApiCall({
    url: couponEditCoupon,
    method: "post",
    data,
  });
};



export const apiCallHomeTransactionGetRevenue = (data) => {
  return baseApiCall({
    url: transactionGetRevenue,
    method: "post",
    data,
  });
};

export const apiCallHomeTransactionGetTaxAmount = (data) => {
  return baseApiCall({
    url: transactionGetTaxAmount,
    method: "post",
    data,
  });
};

export const apiCallUsersCheckUserExistsMobileNumber = (data) => {
  return baseApiCall({
    url: usersCheckUserExists,
    method: "post",
    data,
  });
};


export const getAstrologerWiseSessionList = (param,data) => {
  return baseApiCall({
    url: `${astrologerGetAstrologerSessionList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const getAstrologerTransactionHistoryList = (param,data) => {
  return baseApiCall({
    url: `${astrologerGetAstrologerTransactionHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};


export const getAstrologerWithdrawalHistoryList = (param,data) => {
  return baseApiCall({
    url: `${astrologerGetAstrologerWithdrawalsHistory}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const getApiCallScheduleNotificationList = (param,data) => {
  return baseApiCall({
    url: `${getScheduleNotificationList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};

export const getApiHistoryNotificationList = (param,data) => {
  return baseApiCall({
    url: `${notificationGetAdminNotificationList}?offset=${param.offset}&limit=${param.limit}`,
    method: "post",
    data,
  });
};






export const apiCallSendNotificationUSer = (data) => {
  return baseApiCall({
    url: notificationSendNotifications,
    method: "post",
    data,
  });
};



export const apiCallNotificationEditNotification = (data) => {
  return baseApiCall({
    url: notificationEditNotification,
    method: "post",
    data,
  });
};



export const apiCallDeleteNotification = (data) => {
  return baseApiCall({
    url: notificationDeleteNotification,
    method: "post",
    data,
  });
};



export const onApiCallAstrologerActiveInactive = (data) => {
  return baseApiCall({
    url: astrologerActiveInactive,
    method: "post",
    data,
  });
};




export const onApiCallAddBAnner = (data) => {
  return baseApiCall({
    url: bannerCreateNewBanner,
    method: "post",
    data,
  });
};



export const onAPiCallGetAllBanners = (data) => {
  return baseApiCall({
    url: bannerGetAllBanners,
    method: "post",
    data,
  });
};

export const onApiCallEditBanner = (data) => {
  return baseApiCall({
    url: bannerEditBanner,
    method: "post",
    data,
  });
};


export const onApiCallInActiveBanner = (data) => {
  return baseApiCall({
    url: bannerActiveInactiveBanner,
    method: "post",
    data,
  });
};


export const onApiCallShopGetAllShopCategories = (data) => {
  return baseApiCall({
    url: shopGetAllShopCategories,
    method: "post",
    data,
  });
};

