import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import CustomInventoryTitleList from "../../../Components/CustomOrderListScreen/CustomInventoryTitleList";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import {
  post_api_call_shop_getInventoryOverview,
  post_api_shop_getProductList,
  post_delete_product_deleteProduct,
} from "../../../Core/Apicall";
import EmptyData from "../../../Components/EmptyData/EmptyData";

function Inventory() {
  const [orderListData, setOrderListData] = useState([]);
  const [orderListAllData, setOrderListAllData] = useState([]);
  const [dropdownSelected, setDropDownSelectedValue] = useState("By Status");
  // const [initialPage, setInitialPage] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  const statusDropdownItem = [
    "All",
    "In Stock",
    "Low Stock",
    "Out of Stock",
    "Inactive",
  ];

  const [sortOrder, setSortOrder] = useState("asc");
  const [searchValue, setSearchValue] = useState("");
  const [totalProductList, setTotalProductList] = useState(0);
  const [overViewData, setOverViewData] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onApiCallOverview();
    onAPiCallProductList();
  }, [pageComments]);

  const onApiCallOverview = () => {
    post_api_call_shop_getInventoryOverview()
      .then((response) => {
        setOverViewData(response.data);
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onAPiCallProductList = (selectedPage) => {
    const param = {
      offset: 100 * pageComments,
      limit: 100,
    };
    dispatch(setLoadingTrue());
    setDropDownSelectedValue("By Status");
    setSearchValue("");
    // setInitialPage(selectedPage?.selected || 0);
    post_api_shop_getProductList(param)
      .then((response) => {
        // setOrderListData(response.productList);
        // setOrderListAllData(response.productList);

        setOrderListData((prevData) => [...prevData, ...response.productList]);
        setOrderListAllData((prevData) => [
          ...prevData,
          ...response.productList,
        ]);

        setTotalProductList(Math.ceil(response.productCount / 100));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleSelect = (status) => {
    setDropDownSelectedValue(status);
    setSearchValue("");
    if (status === "All") {
      setOrderListData(orderListAllData);
    } else {
      let statusString = "";
      if (status == "In Stock") {
        statusString = "inStock";
      } else if (status == "Low Stock") {
        statusString = "lowStock";
      } else if (status == "Out of Stock") {
        statusString = "outOfStock";
      } else {
        statusString = "inActive";
      }
      const filteredData = orderListAllData.filter(
        (filData) => filData.productStatus === statusString
      );
      setOrderListData(filteredData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const searchFilterFunction = (text) => {
    setDropDownSelectedValue("By Status");
    if (text) {
      const newData = orderListAllData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setOrderListData(newData);
    } else {
      setOrderListData(orderListAllData);
    }
  };

  const sortByHeader = (property) => {
    const sortedData = [...orderListData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setOrderListData(sortedData);
  };

  const onClickDeleteInventory = async (item) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this product?"
    );
    if (confirmed) {
      dispatch(setLoadingTrue());
      try {
        let param = {
          productId: item.productId,
        };
        await post_delete_product_deleteProduct(param);
        const updatedOrderListData = orderListData?.map((data) => {
          if (data.productId === item.productId) {
            return { ...data, productStatus: "inActive" };
          }
          return data;
        });
        setOrderListData(updatedOrderListData);
        setOrderListData(updatedOrderListData);
        onApiCallOverview();
        dispatch(setLoadingFalse());
      } catch (error) {
        dispatch(setLoadingFalse());
      }
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalProductList > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Zigzek shop / Inventory"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Inventory overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">All inventory</div>
                <div className="fontSize24">
                  {overViewData?.totalProductCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Low stock</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {overViewData?.lowStockProductCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Out of stock</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {overViewData?.outOfStockProductCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Inactive</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {overViewData?.inActiveProductCount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ paddingTop: "1rem", paddingBottom: "1rem" }}
          className="astrologers-list-parent"
        >
          <div style={{}} className="fontSize24">
            Product list
          </div>
          <div className="frame-parent2">
            <div className="custom-box-user">
              <div className="custom-container-dropdown-div">
                <CustomDropdown
                  dropDownLabel={dropdownSelected}
                  items={statusDropdownItem}
                  onSelect={handleSelect}
                  color="blue"
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div className="custom-box-search-div">
                <img className="custom-image" alt="" src={Icons.ic_search} />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  cursor: "pointer",
                  background: Colors.white,
                  padding: "0.7rem 0.7rem",
                  gap: "0.5rem",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => navigate("/AddInventory")}
              >
                <div className="darkGreyFont16" style={{ color: Colors.black }}>
                  Add new Product
                </div>
                <img
                  src={Icons.ic_add}
                  style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </div>

        <CustomInventoryTitleList
          data={orderListData}
          onClickDeleteInventory={onClickDeleteInventory}
          currentStockSort={() => sortByHeader("currentStock")}
          sellingPriceSort={() => sortByHeader("sellingPrice")}
          dailyOrdersSort={() => sortByHeader("dailyOrders")}
          totalOrderSort={() => sortByHeader("totalOrders")}
          handleScroll={handleScroll}
          commentsContainerRef={commentsContainerRef}
        />
        {orderListData?.length <= 0 && <EmptyData />}
        {/* <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            initialPage={initialPage}
            pageCount={totalProductList}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onAPiCallProductList}
          />
        </div> */}
      </div>
    </div>
  );
}

export default Inventory;
