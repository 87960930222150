import React from "react";
import "./../../Constants/CommonStyle.css";
import Icons from "../../Constants/Icons";
import { useNavigate } from "react-router-dom";
import Colors from "../../Constants/Colors";
import StatusButton from "../../Components/StatusButton/StatusButton";
import { useState } from "react";

function RegistrionRenderList({ data, name, transactionId, amount, status }) {
  const [openMenuIndex, setOpenMenuIndex] = useState(-1);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (index) => {
    if (index === openMenuIndex) {
      setOpenMenuIndex(-1);
    } else {
      setOpenMenuIndex(index);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setOpenMenuIndex(-1);
  };
  const onClickIdCopy = () => {
    navigator.clipboard.writeText("");
  };
  return (
    <div style={{ alignSelf: "stretch" }}>
      {data.map((item, index) => {
        return (
          <div
            style={{
              padding: "1rem",
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: "1",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                gap: "0.5rem",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",

                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                  alignSelf: "stretch",
                }}
              >
                <div
                  style={{
                    display: "inline-block",
                    fontFamily: "Poppins-Regular",
                    fontSize: "0.8rem",
                    fontWeight: 600,
                    color: "#fff",
                    position: "relative",
                  }}
                  className="fontSize18Weight400"
                >
                  56789 - 675 - 436
                </div>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={() => onClickIdCopy()}
                  className="icon_copy"
                  alt=""
                  src={Icons.ic_copy}
                />
              </div>
              <div
                style={{
                  width: "16%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={Icons.ic_default_user}
                  />
                </div>
                <div className="fontSize18Weight400">{item.name}</div>
              </div>
              <div
                style={{
                  width: "20%",
                  fontSize: "0.88rem",
                  fontFamily: "Poppins-Regular",
                  color: Colors.white,
                  position: "relative",
                }}
                className="fontSize18Weight400"
              >
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_sms}
                  />
                  {item.email}
                </div>
                <div
                  style={{
                    paddingTop: "0.5rem",
                    alignItems: "center",
                    display: "flex",
                    gap: "0.4rem",
                  }}
                  className="fontSize18Weight400"
                >
                  <img
                    style={{ width: "1.2rem", objectFit: "contain" }}
                    src={Icons.ic_call}
                  />
                  {item.phone}
                </div>
              </div>
              <div
                style={{
                  width: "10%",
                }}
                className="fontSize18Weight400"
              >
                7+
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "0.25rem",
                  fontSize: "0.75rem",
                  fontFamily: "Poppins-Regular",
                  width: "18%",
                }}
              >
                <div
                  style={{
                    borderRadius: "46px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "1.75rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.38rem 1rem",
                  }}
                >
                  <div
                    style={
                      {
                        // color: Colors.white,
                        // position: "relative",
                        // fontWeight: "600",
                        // fontFamily: "Poppins-Regular",
                      }
                    }
                    className="fontSize18Weight400"
                  >
                    Quick
                  </div>
                </div>
                <div
                  style={{
                    borderRadius: "46px",
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "1.75rem",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0.38rem 1rem",
                  }}
                >
                  <div
                    style={
                      {
                        // color: Colors.white,
                        // position: "relative",
                        // fontWeight: "600",
                        // fontFamily: "Poppins-Regular",
                      }
                    }
                    className="fontSize18Weight400"
                  >
                    Chat
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "8%",
                }}
                className="fontSize18Weight400"
              >
                {item.gender}
              </div>
              <StatusButton
                style={{
                  width: "10%",
                  backgroundColor:
                    item.status === "Approved"
                      ? Colors.mainGreen01
                      : item.status === "Rejected"
                      ? Colors.mainRed01
                      : item.status === "New"
                      ? Colors.mainBlue01
                      : Colors.mainOrange01,
                  borderColor:
                    item.status === "Approved"
                      ? Colors.darkGreen
                      : item.status === "Rejected"
                      ? Colors.darkRed
                      : item.status === "New"
                      ? Colors.darkBlue
                      : Colors.darkOrange,
                  color:
                    item.status === "Approved"
                      ? Colors.darkGreen
                      : item.status === "Rejected"
                      ? Colors.darkRed
                      : item.status === "New"
                      ? Colors.darkBlue
                      : Colors.darkOrange,
                }}
              >
                {item.status}
              </StatusButton>
              <div
                onClick={() => toggleMenu(index)}
                style={{ cursor: "pointer", width: "2%", display: "flex" }}
              >
                <img
                  style={{ width: "1.2rem", objectFit: "cover" }}
                  src={Icons.ic_more}
                />
              </div>
              <div className="custom-menu-popup-container">
                {openMenuIndex === index && (
                  <div style={{width:"auto"}} onMouseLeave={closeMenu} className="custom-menu-popup">
                    <div className="custom-menu-list">Accept</div>
                    <div className="custom-menu-list">Reject</div>
                    <div className="custom-menu-list">Mark as approved</div>
                    <div className="custom-menu-list">Publish astrologer</div>
                    <div className="custom-menu-list">Delete</div>
        
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RegistrionRenderList;
