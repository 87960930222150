import React from "react";
import Icons from "../../../../Constants/Icons";
import Colors from "../../../../Constants/Colors";
import StatusButton from "../../../../Components/StatusButton/StatusButton";
import moment from "moment";

function Astrologertransactionlist({
  transactionID,
  Astrologer,
  dateTime,
  Amount,
  Note,
  status,
  data,
  dateTimeSort,
  amountSort,
  AstrologerHide,
  TransactionamountHide,
  TaxamountHide,
  Outstandingamount,
  handleScroll,
  commentsContainerRef
}) {
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: "1",
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.8rem",
        }}
      >
        <div style={{ width: "22%" }} className="contentName">
          {transactionID}
        </div>
        {!AstrologerHide && (
          <div style={{ width: "15%" }} className="contentName">
            {Astrologer}
          </div>
        )}
        <div
          onClick={dateTimeSort}
          style={{
            width: "15%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {dateTime}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div
          onClick={amountSort}
          style={{
            width: "10%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {!TransactionamountHide && <div>{Amount}</div>}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>

        {/* <div
          onClick={dateTimeSort}
          style={{
            width: "13%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {!TaxamountHide && <div>{Outstandingamount}</div>}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div> */}

        <div
          style={{
            width: "22%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {Note}
          </div>
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {status}
        </div>
      </div>

      <div style={{ maxHeight: "500px" }} onScroll={handleScroll} ref={commentsContainerRef} className="scrollable-container">
        {data.map((item, index) => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "0.8rem",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "22%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    alignSelf: "stretch",
                  }}
                >
                  <div className="fontSize18Weight400">{item._id}</div>
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => navigator.clipboard.writeText(item._id)}
                    className="icon_copy"
                    alt=""
                    src={Icons.ic_copy}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={item?.astrologerImage}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="fontSize18Weight400">
                    {item?.astrologerName}
                  </div>
                  <div
                    style={{ color: Colors.secondary }}
                    className="fontSize18Weight400"
                  >
                    {item?.astrologerRole}
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">
                  {moment(item?.dateAndTime).format("DD-MM-YYYY")}
                  <br />
                  {moment(item?.dateAndTime).format("hh:mm a")}
                </div>
              </div>
              <div
                style={{
                  width: "10%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">
                  {item.totalPaidAmount}
                </div>
              </div>
              <div
                style={{
                  width: "22%",
                  display: "flex",
                  // flexDirection: "row",
                  alignItems: "center",
                  // justifyContent: "flex-start",
                  wordBreak: "break-all",
                }}
              >
                <div className="fontSize18Weight400">{item.note}</div>
              </div>

              <StatusButton
                style={{
                  width: "10%",
                  backgroundColor:
                    item.status === "successful"
                      ? Colors.mainGreen01
                      : item.status === "Failed"
                      ? Colors.mainRed01
                      : Colors.mainOrange01,
                  borderColor:
                    item.status ==="successful"
                      ? Colors.darkGreen
                      : item.status === "Failed"
                      ? Colors.darkRed
                      : Colors.darkOrange,
                  color:
                    item.status === "successful"
                      ? Colors.darkGreen
                      : item.status === "Failed"
                      ? Colors.darkRed
                      : Colors.darkOrange,
                }}
              >
                {item.status}
              </StatusButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Astrologertransactionlist;
