import { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./UserProfile.css";
import "./../../../Constants/CommonStyle.css";
import Icons from "../../../Constants/Icons";
import Header from "../../../Components/Header/Header";
import UserProfileTab from "./UserTab/UserProfileTab";
import UserSessionTab from "./UserTab/UserSessionTab";
import TransactionContentTitle from "../../../Components/CustomTransactionScreen/TransactionContentTitle";
import Modal from "react-modal";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  apiGetUserTransactionHistory,
  getUserDetails,
  post_sendGiftToUser,
} from "../../../Core/Apicall";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import moment from "moment";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import { toast } from "react-toastify";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Transparent background
  },
  content: {
    padding: 0, // Remove padding
    margin: "auto", // Center horizontally
    maxWidth: "400px", // Optional: set a maximum width for the modal
    width: "100%", // Optional: make the modal responsive
    border: "none", // Remove border (if any)
    background: "none", // Transparent background
    display: "flex", // Use flexbox for vertical centering
    alignItems: "center", // Center vertically
    justifyContent: "center", // Center horizontally
  },
};
const UserProfile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { userId } = location.state;

  const tabs = ["Profile", "Sessions", "Wallet transactions"];
  const [activeTab, setActiveTab] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userProfileData, setUserProfileData] = useState();
  const [userProfileOverview, setUserProfileOverview] = useState();
  const [inputValue, setInputValue] = useState("");
  const [
    totalTransactionHistoryPageCount,
    setTotalTransactionHistoryPageCount,
  ] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc");
  const statusBySort = ["All", "Success", "Failed"];
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [transactionHistoryAllData, setTransactionHistoryAllData] = useState(
    []
  );
  const [dropdownSelected, setDropDownSelectedValue] = useState("By Status");
  const sortByItem = ["Date & Time"];
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  useEffect(() => {
    dispatch(setLoadingTrue());
    getUserDetails({ userId: userId })
      .then((response) => {
        console.log("responseresponseresponse", response);
        setUserProfileData(response?.UserDetail);
        setUserProfileOverview(response);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  }, []);

  const onApiCallSendGifts = () => {
    const param = {
      giftAmount: inputValue,
      userId: userId,
    };
    dispatch(setLoadingTrue());
    post_sendGiftToUser(param)
      .then((response) => {
        console.log("response", response);
        toast.success(response.message, {
          autoClose: 1200,
          // hideProgressBar: false,
          // draggable: false,
        });
        dispatch(setLoadingFalse());
        closeModal();
      })
      .catch((err) => {
        toast.error(err, {
          autoClose: 1500,
          // hideProgressBar: false,
          // draggable: false,
        });
        closeModal();
        dispatch(setLoadingFalse());
        // setTimeout(() => {
        //   alert(err);
        // }, 200);
      });
  };
  const closeModal = () => {
    setInputValue("");
    setIsModalOpen(false);
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const handleInputChange = (event) => {
    setInputValue(event.target.value.replace(/[^0-9.]/g, ""));
  };

  const sortByDuration = (property) => {
    setDropDownSelectedValue("By Status");
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");

      sortedData.sort((a, b) => {
        const dateA = new Date(a.date_and_time);
        const dateB = new Date(b.date_and_time);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setTransactionHistoryData(sortedData);
    }
  };

  const sortByAMount = (property) => {
    const sortedData = [...transactionHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setTransactionHistoryData(sortedData);
  };

  const filterAndSortData = (status) => {
    setDropDownSelectedValue(status);
    let stringStatusChange;
    if (status == "Success") {
      stringStatusChange = "success";
    } else if (status == "Failed") {
      stringStatusChange = "failed";
    }
    setTransactionHistoryData((prevState) => {
      if (status === "All") {
        return transactionHistoryAllData;
      } else {
        return transactionHistoryAllData.filter(
          (item) => item.status === stringStatusChange
        );
      }
    });
  };

  useEffect(() => {
    if (activeTab == 2) {
      onClickTransactionHistoryApi();
    }
  }, [activeTab, pageComments]);

  const onClickTransactionHistoryApi = () => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };

    let data = {
      userId: userId,
    };
    dispatch(setLoadingTrue());
    apiGetUserTransactionHistory(param, data)
      .then((response) => {
        setDropDownSelectedValue("By Status");
       

        setTransactionHistoryData((prevData) => [
          ...prevData,
          ...response.data,
        ]);
        setTransactionHistoryAllData((prevData) => [
          ...prevData,
          ...response.data,
        ]);




        setTotalTransactionHistoryPageCount(
          Math.ceil(response.totalTransactionsCount / 100)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalTransactionHistoryPageCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"User / " + userProfileData?.userName} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              flex: 0.3,
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1.5rem",
            }}
          >
            <div
              style={{
                borderRadius: "87px",
                backgroundColor: "#25252d",
                width: "5rem",
                height: "5rem",
                overflow: "hidden",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                style={{
                  margin: "0",
                  top: "calc(50% - 55px)",
                  left: "calc(50% - 60px)",
                  width: "5rem",
                  height: "5rem",
                  objectFit: "cover",
                  zIndex: "0",
                }}
                alt=""
                src={userProfileData?.profilePic ?? Icons.ic_man_large_default}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div className="fontSize24">{userProfileData?.userName}</div>
                <div
                  className="fontSize14"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.25rem",
                    color: "#12b76a",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor: "#12b76a",
                        filter: "blur(9px)",
                        width: "0.38rem",
                        height: "0.38rem",
                        opacity: "0.5",
                      }}
                    />
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor: "#12b76a",
                        width: "0.38rem",
                        height: "0.38rem",
                        marginLeft: "-0.37rem",
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {userProfileData?.status}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <div
                  className="fontSize16"
                  style={{ color: Colors.greyColor50 }}
                >
                  {userProfileData?.userId}
                </div>
                <img
                  onClick={() =>
                    navigator.clipboard.writeText(userProfileData?.userId)
                  }
                  style={{
                    position: "relative",
                    width: "1rem",
                    objectFit: "cover",
                    height: "1rem",
                    cursor: "pointer",
                    flexShrink: "0",
                  }}
                  alt=""
                  src={Icons.ic_copy}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
              >
                <img
                  style={{
                    width: "1.2rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_white_wallet}
                />
                <div className="fontSize16" style={{ color: Colors.white }}>
                  {userProfileData?.walletBalance}{" "}
                  <span
                    onClick={() => {
                      setInputValue("");
                      setIsModalOpen(true);
                    }}
                    style={{ color: Colors.darkBlue, cursor: "pointer" }}
                  >
                    Send Gifts
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
              flex: 0.7,
            }}
          >
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Joining date
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                {userProfileOverview?.joiningDate ?? "-"}
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Avg. order size
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                {userProfileOverview?.avgOrderSize ?? 0}
              </div>
            </div>
            {/* <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Avg. time spends
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                {userProfileData?.avgTimeSpend}
              </div>
            </div> */}
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Total expenses
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                {userProfileOverview?.totalExpenses ?? 0}
              </div>
            </div>
          </div>
        </div>

        <div className="user-profile-custom-container">
          {tabs.map((tab, index) => (
            <div
              key={index.toString()}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
        </div>

        {activeTab === 0 && (
          <UserProfileTab userProfileData={userProfileData} />
        )}
        {activeTab === 1 && <UserSessionTab userId={userProfileData?.userId} />}
        {activeTab === 2 && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <div style={{}} className="sectionDivStyle">
              <div
                className="title24FontWeight400WHite"
                style={{
                  flex: "1",
                  position: "relative",
                  alignSelf: "stretch",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                Transaction history
              </div>
              <div style={{}} className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    fontSize: "1rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      border: Colors.darkBorderColor,
                      boxSizing: "border-box",
                      width: "8.38rem",
                      display: "flex",
                      zIndex: 1,
                      padding: "0.5rem 0.5rem",
                    }}
                  >
                    <CustomDropdown
                      dropDownLabel={"Sort by"}
                      items={sortByItem}
                      color="blue"
                      onSelect={sortByDuration}
                      customLabelStyle={{
                        fontWeight: "400",
                        color: Colors.greyColor50,
                      }}
                    />
                  </div>
                  <div
                    style={{
                      borderRadius: "8px",
                      border: Colors.darkBorderColor,
                      boxSizing: "border-box",
                      width: "8.38rem",
                      display: "flex",
                      zIndex: 1,
                      padding: "0.5rem 0.5rem",
                    }}
                  >
                    <CustomDropdown
                      dropDownLabel={dropdownSelected}
                      items={statusBySort}
                      color="blue"
                      onSelect={(selectedStatus) => {
                        filterAndSortData(selectedStatus);
                      }}
                      customLabelStyle={{
                        fontWeight: "400",
                        color: Colors.greyColor50,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <TransactionContentTitle
              handleScroll={handleScroll}
              commentsContainerRef={commentsContainerRef}
              data={transactionHistoryData}
              transactionID={"Transaction ID"}
              from={"From"}
              to={"To"}
              dateTimeSort={() => sortByDuration("Date & Time")}
              amountSort={() => sortByAMount("amount")}
              dateTime={"Date & Time"}
              transactionType={"Transaction for"}
              amount={"Amount"}
              status={"Status"}
            />

            {/* {transactionHistoryData.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={totalTransactionHistoryPageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={onClickTransactionHistoryApi}
                />
              </div>
            )} */}
          </div>
        )}
      </div>
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
      >
        <div
          style={{
            background: Colors.mainDarkColor,
            width: "80%",
            padding: 20,
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="fontSize24">Send gift</div>
            <img
              src={Icons.ic_close}
              alt="ic_close"
              onClick={() => setIsModalOpen(false)}
              style={{
                cursor: "pointer",
                alignSelf: "center",
                justifyContent: "center",
                display: "flex",
                height: "1.3rem",
                width: "1.3rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              paddingTop: "40px",
              flexDirection: "column  ",
            }}
          >
            <div className="fontSize16">Enter amount</div>
            <input
              style={{
                background: "transparent",
                display: "flex",
                paddingLeft: "10px",
                paddingRight: "10px",
                marginTop: "10px",
                height: "2rem",
                color: Colors.white,
                border: "1px solid rgba(255, 255, 255, 0.2)",
                borderRadius: "30px",
                outline: "none",
              }}
              type="text"
              placeholder="₹"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "flex", paddingTop: "40px", gap: "1rem" }}>
            <div
              onClick={() => setIsModalOpen(false)}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                flex: 1,
                borderRadius: "10rem",
                border: "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize16"
            >
              Cancel
            </div>
            <div
              onClick={() => onApiCallSendGifts()}
              style={{
                justifyContent: "center",
                display: "flex",
                alignItems: "center",
                height: "2rem",
                flex: 1,
                background: "#ffff",
                borderRadius: "10rem",
                color: Colors.black,
                cursor: "pointer",
              }}
              className="fontSize16"
            >
              Send
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UserProfile;
