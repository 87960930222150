import React, { useState } from "react";
import { css } from "@emotion/react";
import {
  BeatLoader,
  BounceLoader,
  CircleLoader,
  ClipLoader,
  FadeLoader,
  MoonLoader,
  PropagateLoader,
  RingLoader,
  RiseLoader,
  SyncLoader,
} from "react-spinners";
import { useSelector } from "react-redux";

const Loader = ({}) => {
  const isLoading = useSelector((store) => store.isLoading);
  // const [isLoading, setIsLoading] = useState(isLoading);
  // console.log("storestorestorestore", isLoading);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.8)",
        zIndex: 999,
        visibility: isLoading ? "visible" : "hidden",
      }}
    >
      <div>
        <ClipLoader
          color={"#FFF"}
          loading={true}
          css={spinnerOverride}
          size={35}
        />
      </div>
    </div>
  );
};

const spinnerOverride = css`
  display: block;
  margin: 0 auto;
`;

export default Loader;
