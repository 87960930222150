// // store.js
// import { createStore, applyMiddleware } from "redux";
// import rootReducer from "./Reducer";

// const store = createStore(rootReducer);

// export default store;



// // store.js
// import rootReducer from "./Reducer";
// import { createStore, applyMiddleware } from 'redux'; // Check this import


// const store = createStore(rootReducer);

// export default store;


// store.js
import rootReducer from "./Reducer";
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'; 
import storage from 'redux-persist/lib/storage'; 

// Persist config
const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);
export { store, persistor };

