import { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import "../../../../Constants/CommonStyle.css";
import { useNavigate } from "react-router-dom";
import "./SessionLive.css";
import Images from "../../../../Constants/Images";
import Header from "../../../../Components/Header/Header";
import Icons from "../../../../Constants/Icons";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { post_api_live_session_live_session_list } from "../../../../Core/Apicall";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import moment from "moment";
const SessionLive = () => {
  const tabs = ["Ongoing", "Schedule", "Completed", "Missed"];
  const [activeTab, setActiveTab] = useState(0);

  const [totalHeaderOngoing, setHeaderTotalOngoing] = useState(0);
  const [totalHeaderSchedule, setHeaderTotalSchedule] = useState(0);
  const [totalHeaderComplete, setHeaderTotalComplete] = useState(0);
  const [totalHeaderMissed, setHeaderTotalMissed] = useState(0);

  const [liveSessionData, setLiveSessionData] = useState([]);
  const [totalLiveSessionPageCount, setTotalLiveSessionPageCount] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleTabClick = (index) => {
    setLiveSessionData([]);
    setPageComments(0);
    setActiveTab(index);
  };

  useEffect(() => {
    onApiCallLiveSessionList();
  }, [activeTab, pageComments]);

  const onApiCallLiveSessionList = (selectedPage) => {
    const param = {
      offset: 100 * pageComments,
      limit: 100,
      type:
        activeTab == 0
          ? "ongoing"
          : activeTab == 1
          ? "schedule"
          : activeTab == 2
          ? "completed"
          : "missed",
    };
    dispatch(setLoadingTrue());
    post_api_live_session_live_session_list(param)
      .then((response) => {
        setLiveSessionData((prevData) => [
          ...prevData,
          ...response?.liveSessionList,
        ]);

        setHeaderTotalOngoing(response?.totalOngoingSessionCount);
        setHeaderTotalSchedule(response?.totalScheduledSessionCount);
        setHeaderTotalComplete(response?.totalCompletedSessionCount);
        setHeaderTotalMissed(response?.totalMissedSessionCount);
        setTotalLiveSessionPageCount(
          Math.ceil(response.totalSessionsCount / 100)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalLiveSessionPageCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  const renderItemGridList = () => {
    return (
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="grid-container    scrollable-container"
      >
        {liveSessionData?.map((item, index) => (
          <div
            key={index}
            onClick={() =>
              navigate("/SessionStatusDetails", {
                state: {
                  streamId: item.streamId,
                },
              })
            }
            style={{ cursor: "pointer" }}
            className="card-session-container"
          >
            <div
              className="fontSize16"
              style={{ width: "12rem", textAlign: "center" }}
            >
              {item?.liveSessionTitle}
            </div>
            <div className="card-session-image-container">
              <img
                src={item?.astrologerImage ?? Images.ic_default_man}
                alt=""
                className="card-session-image"
              />
              <div className="card-session-image-overlay" />
            </div>
            <div className="card-session-info">
              <div
                className="fontSize20"
                style={{
                  letterSpacing: "-0.28px",
                  fontSize: "0.75rem",
                  fontWeight: 500,
                }}
              >
                {item?.astrologerName}
              </div>
              <div
                style={{
                  fontSize: "0.65rem",
                  textAlign: "center",
                  color: Colors.secondary,
                }}
                className="fontSize18"
              >
                {item?.astrologerRole}
              </div>
              {activeTab == 2 && (
                <div
                  style={{
                    padding: "5px",
                    fontSize: "0.65rem",
                    textAlign: "center",
                    color: Colors.secondary,
                  }}
                  className="fontSize18"
                >
                  {/* 19-07-2023, 09:30 AM */}

                  {moment(item?.date_and_time).format("DD-MM-YYYY")}
                  {", " + moment(item?.date_and_time).format("hh:mm a")}
                </div>
              )}
            </div>

            {(activeTab == 0 || activeTab == 2) && (
              <div className="card-session-duration">
                <img
                  src={Icons.ic_orange_duration}
                  alt=""
                  className="card-session-icon"
                />
                <div>1h 36m</div>
              </div>
            )}
            {activeTab == 1 && (
              <div className="dateTime-item">
                <div>
                  <img
                    className="card-session-icon-watch"
                    src={Icons.ic_watch}
                  />
                </div>
                <div style={{ fontSize: "0.7rem" }} className="fontSize14">
                  {moment(item?.date_and_time).format("DD-MM-YYYY")}
                  {", " + moment(item?.date_and_time).format("hh:mm a")}
                </div>
              </div>
            )}
            {activeTab == 3 && (
              <div className="dateTime-item">
                <div>
                  <img
                    className="card-session-icon-watch"
                    src={Icons.ic_grey_clock}
                  />
                </div>
                <div
                  style={{ fontSize: "0.7rem", color: Colors.greyColor50 }}
                  className="fontSize14"
                >
                  {moment(item?.date_and_time).format("DD-MM-YYYY")}
                  {", " + moment(item?.date_and_time).format("hh:mm a")}
                </div>
              </div>
            )}
            {(activeTab == 0 || activeTab == 2) && (
              <div className="card-session-stats">
                <div
                  style={{ background: "rgba(18, 183, 106, 0.1)" }}
                  className="card-session-stat"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={Icons.ic_green_eye}
                      alt=""
                      className="card-session-icon"
                    />
                  </div>
                  <div className="card-session-stat-count">
                    {item?.totalViewer}
                  </div>
                </div>
                <div
                  style={{ background: "rgba(46, 144, 250, 0.1)" }}
                  className="card-session-stat"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={Icons.ic_blue_messages}
                      alt=""
                      className="card-session-icon"
                    />
                  </div>
                  <div style={{ color: Colors.darkBlue }}>
                    {item?.totalLiveMessageCount} comments
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Sessions / Live sessions"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div className="user-profile-custom-container">
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => handleTabClick(index)}
            >
              {tab}(
              {index === 0
                ? totalHeaderOngoing
                : index === 1
                ? totalHeaderSchedule
                : index === 2
                ? totalHeaderComplete
                : totalHeaderMissed}
              )
            </div>
          ))}
        </div>
        {renderItemGridList()}
        
        {/* {liveSessionData.length != 0 && (
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              pageCount={totalLiveSessionPageCount}
              pageRangeDisplayed={3}
              initialPage={initialPage}
              marginPagesDisplayed={1}
              onPageChange={onApiCallLiveSessionList}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SessionLive;
