import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./TransactionScreen.css";
import "./../../../Constants/CommonStyle.css";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import TransactionHistoryList from "../../../Components/CustomTransactionScreen/TransactionHistoryList";
import TransactionContentTitle from "../../../Components/CustomTransactionScreen/TransactionContentTitle";
import Header from "../../../Components/Header/Header";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../Components/EmptyData/EmptyData";
const userChartData = {
  labels: ["Green", "Yellow", "Blue", " Red", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [4, 6, 20, 3],
      backgroundColor: [
        Colors.darkOrange,
        Colors.darkBlue,
        Colors.darkGreen,
        Colors.darkRed,
      ],
      borderWidth: 0,
    },
  ],
};

const userChartData2 = {
  datasets: [
    {
      label: "# of Votes",
      data: [12, 18],
      backgroundColor: [Colors.darkGreen, Colors.darkBlue],
      borderWidth: 0,
    },
  ],
};

const initialTransactionHistoryData = [
  {
    name: "Rajesh",
    transactionId: "56789-675-436",
    amount: "₹150",
    dateTime: "19-07 09:32 AM",
    transaction_for: ["Wallet", "Bank account"],
    status: "In progress",
  },
  {
    name: "Alice",
    transactionId: "12345-678-901",
    amount: "₹200",
    transaction_for: ["Wallet"],
    dateTime: "19-07 10:45 AM",
    status: "Failed",
  },
  {
    name: "Alice",
    transactionId: "12345-678-901",
    amount: "₹200",
    dateTime: "19-07 10:45 AM",
    transaction_for: ["Wallet", "Bank account"],
    status: "Successful",
  },
  {
    name: "Alice",
    transactionId: "12345-678-901",
    amount: "₹200",
    transaction_for: ["Bank account"],
    dateTime: "19-07 11:15 AM",
    status: "Failed",
  },
];

function Transaction() {
  const items = ["Item 1", "Item 2", "Item 3"];
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const sortByItem = ["Date & Time"];
  const statusDropdownItem = [ "All","In progress", "Failed", "Successful"];
  const [searchValue, setSearchValue] = useState("");
  const [transactionHistoryData, setTransactionHistoryData] = useState(
    initialTransactionHistoryData
  );
  const handleSelect = (item) => {
    setSelectedItem(item);
  };

  const byStatusSort = (status) => {
    setSearchValue("");
    if (status === "All") {
      setTransactionHistoryData(initialTransactionHistoryData); // Show all items
    } else {
      const filteredData = initialTransactionHistoryData.filter(
        (astrologer) => astrologer.status === status
      );
      setTransactionHistoryData(filteredData);
    }
  };
  const parseCustomDate = (dateStr) => {
    const parts = dateStr.split(" ");
    const [date, time] = parts;
    const [day, month] = date.split("-");
    const [hours, minutes] = time.split(":");
    const isAM = parts[2] === "AM";
    const year = new Date().getFullYear();
    const parsedDate = new Date(year, month - 1, day, hours, minutes);
    if (!isAM && parsedDate.getHours() < 12) {
      parsedDate.setHours(parsedDate.getHours() + 12);
    }
    return parsedDate;
  };

  const sortByDuration = (property) => {
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");

      sortedData.sort((a, b) => {
        const dateA = parseCustomDate(a.dateTime);
        const dateB = parseCustomDate(b.dateTime);

        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setTransactionHistoryData(sortedData);
    }
  };

  const parseAvgTime = (value) => {
    const numericValue = parseFloat(
      value.replace(/₹/g, "").replace(/\/min/g, "")
    );
    return numericValue;
  };
  const sortByAMount = (property) => {
    const sortedData = [...transactionHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return parseAvgTime(a[property]) - parseAvgTime(b[property]);
      } else {
        return parseAvgTime(b[property]) - parseAvgTime(a[property]);
      }
    });
    setTransactionHistoryData(sortedData);
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = initialTransactionHistoryData.filter((item) => {
        // const itemData = item.name ? item.name.toUpperCase() : "".toUpperCase();
        // const textData = text.toUpperCase();
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
        // return itemData.indexOf(textData) > -1;
      });
      setTransactionHistoryData(newData);
    } else {
      setTransactionHistoryData(initialTransactionHistoryData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Transaction"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="sectionDivStyle">
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "16px",
              backgroundColor: "#25252d",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 0rem 0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  gap: "1rem",
                }}
              >
                <div className="contentTitle24Weight40">Overview</div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"last Week"}
                    items={items}
                    onSelect={handleSelect}
                  />
                </div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total balance
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹ 1,99,000
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total income
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkGreen,
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹ 2,00,080
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem 0rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Total expenses
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.darkRed,
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹ 10,080
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              // width: "49.38rem",
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Income overview
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"last Week"}
                    items={items}
                    onSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="lastWeekEndDiv" style={{ fontWeight: "600" }}>
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    65826
                  </div>
                </div>
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                >
                  <Doughnut
                    options={{
                      cutout: "75%",
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={userChartData}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkGreen,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Call sessions
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Chat sessions
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkOrange,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Live sessions
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              zIndex: "0",
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Expense overview
                </div>
                <div
                  style={{
                    borderRadius: "8px",
                    border: Colors.darkBorderColor,
                    boxSizing: "border-box",
                    width: "9.38rem",
                    display: "flex",
                    zIndex: 1,
                    padding: "0.38rem 0.75rem",
                  }}
                >
                  <CustomDropdown
                    dropDownLabel={"All time"}
                    items={items}
                    onSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="lastWeekEndDiv" style={{ fontWeight: "600" }}>
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    65826
                  </div>
                </div>
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                >
                  <Doughnut
                    options={{
                      cutout: "75%",
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    data={userChartData2}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignSelf: "stretch",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkGreen,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Refund
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Tax amount
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sectionDivStyle">
          <div
            className="title24FontWeight400WHite"
            style={{
              flex: "1",
              position: "relative",
              alignSelf: "stretch",
              alignItems: "center",
              display: "flex",
            }}
          >
            Transaction history
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
              fontSize: "1rem",
              color: "rgba(255, 255, 255, 0.5)",
            }}
          >
            <div
              style={{
                borderRadius: "8px",
                border: Colors.darkBorderColor,
                boxSizing: "border-box",
                width: "9.38rem",
                display: "flex",
                zIndex: 1,
                padding: "0.5rem 0.5rem",
              }}
            >
              <CustomDropdown
                dropDownLabel={"By status"}
                items={statusDropdownItem}
                color="blue"
                onSelect={byStatusSort}
                customLabelStyle={{
                  fontWeight: "400",
                  color: Colors.greyColor50,
                }}
              />
            </div>

            <div
              style={{
                borderRadius: "8px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                boxSizing: "border-box",
                width: "15.75rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                padding: "0.5rem 0.5rem",
                gap: "0.5rem",
              }}
            >
              <img
                style={{
                  position: "relative",
                  width: "1.5rem",
                  height: "1.5rem",
                  objectFit: "contain",
                }}
                alt=""
                src={Icons.ic_search}
              />
              <input
                type="text"
                className="searchTextInput"
                placeholder="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <TransactionContentTitle
          data={transactionHistoryData}
          transactionID={"Transaction ID"}
          from={"From"}
          to={"To"}
          dateTimeSort={() => sortByDuration("Date & Time")}
          amountSort={() => sortByAMount("amount")}
          dateTime={"Date & Time"}
          transactionType={"Transaction for"}
          amount={"Amount"}
          status={"Status"}
        />

        {transactionHistoryData.length <= 0 && <EmptyData />}
        {transactionHistoryData.length != 0 && (
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              pageCount={10}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              // onPageChange={onClickAstrologerMainListApi}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default Transaction;
