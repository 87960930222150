import React from "react";
import Icons from "../../Constants/Icons";
import Colors from "../../Constants/Colors";
import StatusButton from "../../Components/StatusButton/StatusButton";
import "../../Constants/CommonStyle.css";
import { useNavigate } from "react-router-dom";
import moment from "moment";
function CustomSessionList({ wallet, astrologerData, userId,onClickSession }) {
  console.log("astrologerData",astrologerData);
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        onClickSession != false && navigate("/QuickSessionInfo", {
          state: {sessionQuick:1, roomId: astrologerData?.roomId,astrologerName:astrologerData?.astrologerName, userId: userId },
        })
      }
      
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        padding: "1rem",
        cursor: onClickSession != false &&"pointer",
        alignSelf: "stretch",
      }}
    >
      <div
        style={{
          width: "25%",
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
        }}
      >
        <div style={{}} className="indian-man-smiling-astrologer">
          <img
            style={{
              position: "relative",
              width: "2.63rem",
              height: "2.63rem",
            }}
            alt=""
            src={astrologerData?.astrologerImage ?? astrologerData?.userImage?? Icons.ic_default_user}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div className="fontSize18Weight400">
            {astrologerData?.astrologerName ?? astrologerData?.userName}
          </div>
          <div
            style={{
              position: "relative",
              color: Colors.greyColor50,
            }}
            className="fontSize18Weight400"
          >
            {moment(astrologerData?.date_and_time).format("DD-MM-YYYY")}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",

          gap: "0.25rem",
          width: "23%",
        }}
      >
        {/* {session_type?.map((item) => {
          return ( */}
        <div
          style={{
            borderRadius: "46px",
            border: "1px solid rgba(255, 255, 255, 0.1)",
            boxSizing: "border-box",
            height: "1.75rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.38rem 1rem",
          }}
        >
          <div className="fontSize18Weight400">
            {astrologerData?.sessionType}
          </div>
        </div>
        {/* ); */}
        {/* })} */}
      </div>
      <div
        style={{
          width: "13%",
        }}
        className="fontSize18Weight400"
      >
        {astrologerData?.duration}
      </div>
      <div
        style={{
          width: "13%",
        }}
        className="fontSize18Weight400"
      >
        {astrologerData?.fees ? astrologerData?.fees + "/min" : "-"}
      </div>
      <div
        style={{
          display: "flex",
          gap: "0.2rem",
          width: "13%",
        }}
        className="fontSize18Weight400"
      >
        {"₹" + astrologerData?.totalFees}{" "}
        <div
          style={{
            color:
              astrologerData?.isPaid == true
                ? Colors.darkGreen
                : Colors.darkOrange,
          }}
          className="fontSize18Weight400"
        >
          {astrologerData?.isPaid
            ? "Paid"
            : astrologerData?.isRefund
            ? "Refund"
            : ""}
        </div>
      </div>
      <StatusButton
        style={{
          width: "13%",
          backgroundColor:
            astrologerData?.sessionStatus === "completed" ||
            astrologerData?.sessionStatus == "approved"
              ? Colors.mainGreen01
              : astrologerData?.sessionStatus === "cancel" ||
                astrologerData?.sessionStatus == "declined"
              ? Colors.mainRed01
              : astrologerData?.sessionStatus === "missed"
              ? Colors.lightGrey01
              : Colors.mainOrange01,
          borderColor:
            astrologerData?.sessionStatus === "completed" ||
            astrologerData?.sessionStatus == "approved"
              ? Colors.darkGreen
              : astrologerData?.sessionStatus === "cancel" ||
                astrologerData?.sessionStatus == "declined"
              ? Colors.darkRed
              : astrologerData?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
          color:
            astrologerData?.sessionStatus === "completed" ||
            astrologerData?.sessionStatus == "approved"
              ? Colors.darkGreen
              : astrologerData?.sessionStatus === "cancel" ||
                astrologerData?.sessionStatus == "declined"
              ? Colors.darkRed
              : astrologerData?.sessionStatus === "missed"
              ? Colors.darkGrey
              : Colors.darkOrange,
        }}
      >
        {astrologerData?.sessionStatus}
      </StatusButton>
    </div>
  );
}

export default CustomSessionList;
