
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../../Components/Header/Header";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import SessionList from "../../../../Components/SessionList/SessionList";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";
import { useDispatch } from "react-redux";
import { api_Get_Session_List } from "../../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";

function Completed() {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [sessionListData, setSessionListData] = useState([]);
  const [sessionListAllData, setSessionListAllData] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sessionPageCount, setTotalSessionPageCount] = useState(0);

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);


  useEffect(() => {
    onApiCallGetSessionList();
  }, [pageComments]);

  const onApiCallGetSessionList = (selectedPage) => {
    let param = {
   offset: 100 * pageComments,
      limit: 100,
    };

    let data = {
      type: "completed",
    };
    dispatch(setLoadingTrue());
    api_Get_Session_List(param, data)
      .then((response) => {
        setSearchValue("");
        

        setSessionListData((prevData) => [
          ...prevData,
          ...response?.sessionlist,
        ]);
        setSessionListAllData((prevData) => [
          ...prevData,
          ...response?.sessionlist,
        ]);



        setTotalSessionPageCount(Math.ceil(response.totalSessionsCount / 100));
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const parseAvgTime = (value) => {
    const numericValue = parseFloat(
      value.replace(/₹/g, "").replace(/\/min/g, "")
    );
    return numericValue;
  };
  const onCLickSortingArray = (property) => {
    const sortedData = [...sessionListData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return parseAvgTime(a[property]) - parseAvgTime(b[property]);
      } else {
        return parseAvgTime(b[property]) - parseAvgTime(a[property]);
      }
    });
    setSessionListData(sortedData);
  };
  const searchFilterFunction = (text) => {
    if (text) {
      const newData = sessionListAllData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setSessionListData(newData);
    } else {
      setSessionListData(sessionListAllData);
    }
  };

  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (sessionPageCount > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };


  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Sessions / Completed sessions"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologers-list-parent">
          <div className="fontSize24">Session list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <SessionList
         handleScroll={handleScroll}
         commentsContainerRef={commentsContainerRef}
          onSortingList={() => onCLickSortingArray("totalFees")}
          data={sessionListData}
          sessionId={"Session ID"}
          user={"User"}
          astrologer={"Astrologer"}
          dateTime={"Date & Time"}
          sessionType={"Session type"}
          duration={"Duration"}
          fees={"Fees"}
          totalfees={"Total fees"}
          status={"Status"}
        />
      </div>
      {sessionListData.length <= 0 && <EmptyData />}
      {/* {sessionListData.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={sessionPageCount}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onApiCallGetSessionList}
          />
        </div>
      )} */}
    </div>
  );
}

export default Completed;
