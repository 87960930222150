import React, { useRef } from "react";
import { useEffect, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import "./SendNotification.css";
import Header from "../../../Components/Header/Header";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Icons from "../../../Constants/Icons";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  apiCallNotificationEditNotification,
  apiCallSendNotificationUSer,
} from "../../../Core/Apicall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const CreateNotifiations = ({
  selectNotificationData,
  setSelectedDataClear,
}) => {
  console.log("selectNotificationData", selectNotificationData);
  const dispatch = useDispatch();
  const location = useLocation();
  const [formData, setFormData] = useState({
    title: selectNotificationData?.title ?? "",
    content: selectNotificationData?.content ?? "",
    actionLink: "",
    schedule_notification:  selectNotificationData?.isSchedule ?? false,

    // moment(
    //   formData.dateTime,
    //   "ddd MMM DD YYYY HH:mm:ss ZZ"
    // ).toISOString(),

    dateTime:moment(selectNotificationData?.date_and_time,"YYYY-MM-DDTHH:mm:ss.000Z").format("ddd MMM DD YYYY HH:mm:ss ZZ")?? "",
  });
  const [selectedDate, setSelectedDate] = useState(null);
  const [sendNotiSuccessFully, setSendNotiSuccessfully] = useState(false);
  const [selectedTime, setSelectedTime] = useState(12 * 60);
  const [selectedMeridiem, setSelectedMeridiem] = useState("AM");
  const handleInputChange = (e, fieldName) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const handleDateDateChange = (e) => {
    console.log("eeeeee", e);
    setFormData({
      ...formData,
      dateTime: e,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Additional logic if needed
  };

  const handleSliderChange = (value) => {
    setSelectedTime(value);
    const newDate = new Date(selectedDate);
    newDate.setHours(Math.floor(value / 60), value % 60, 0, 0);
    setSelectedDate(newDate);
  };

  const handleArrowClick = (direction) => {
    const increment = direction === "up" ? 15 : -15;
    const newTime = selectedTime + increment;

    if (newTime >= 0 && newTime <= 24 * 60) {
      setSelectedTime(newTime);
      const newDate = new Date(selectedDate);
      newDate.setHours(Math.floor(newTime / 60), newTime % 60, 0, 0);
      setSelectedDate(newDate);
    }
  };

  const handleMeridiemChange = (newMeridiem) => {
    setSelectedMeridiem(newMeridiem);
    const hours12Format = selectedTime % (12 * 60);
    const newTime =
      newMeridiem === "AM" ? hours12Format : hours12Format + 12 * 60;
    setSelectedTime(newTime);

    // Update the selectedDate to reflect the new time
    const newDate = new Date(selectedDate);
    newDate.setHours(Math.floor(newTime / 60), newTime % 60, 0, 0);
    setSelectedDate(newDate);
  };

  // const CustomTimeInput = React.forwardRef(({ value, onClick }, ref) => (
  //   <div className="custom-datepicker-input">
  //     <button
  //       style={{
  //         background: "none",
  //         border: "1px solid white",
  //         color: "white",
  //         height: "80px",
  //         width: "113px",
  //         borderRadius: "10px",
  //       }}
  //       onClick={onClick}
  //     >
  //       {value}
  //     </button>
  //     <div className="slider-container">
  //       <div className="arrow-button1" onClick={() => handleArrowClick("up")}>
  //         <span class="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
  //       </div>

  //       <div className="arrow-button" onClick={() => handleArrowClick("down")}>
  //         <span class="react-datepicker__navigation-icon react-datepicker__navigation-icon--next"></span>
  //       </div>
  //     </div>
  //     <div className="meridiem-container">
  //       <label
  //         className={`meridiem-label ${
  //           selectedMeridiem === "AM" ? "selected" : ""
  //         }`}
  //       >
  //         <input
  //           type="radio"
  //           value="AM"
  //           checked={selectedMeridiem === "AM"}
  //           onChange={() => handleMeridiemChange("AM")}
  //         />
  //         <span>AM</span>
  //       </label>
  //       <label
  //         className={`meridiem-label ${
  //           selectedMeridiem === "PM" ? "selected" : ""
  //         }`}
  //       >
  //         <input
  //           type="radio"
  //           value="PM"
  //           checked={selectedMeridiem === "PM"}
  //           onChange={() => handleMeridiemChange("PM")}
  //         />
  //         <span>PM</span>
  //       </label>
  //     </div>
  //   </div>
  // ));

  const dateInputRef = useRef(null);

  const handleIconClick = () => {
    // dateInputRef.current.showPicker();
  };

  const onClickSendNotification = async () => {
    // apiCallNotificationEditNotification

    console.log("formData.dateTime", formData.dateTime);

    let param = {
      title: formData?.title,
      content: formData?.content,
      isSchedule: formData?.schedule_notification,
      date_and_time:
        formData?.schedule_notification == false
          ? moment().toISOString()
          : moment(
              formData.dateTime,
              "ddd MMM DD YYYY HH:mm:ss ZZ"
            ).toISOString(),
    };
    try {
      dispatch(setLoadingTrue());
      if (selectNotificationData) {
        param.notificationId = selectNotificationData._id;
        await apiCallNotificationEditNotification(param);
      } else {
        await apiCallSendNotificationUSer(param);
      }
      setSendNotiSuccessfully(true);
      setFormData({
        title: "",
        content: "",
        actionLink: "",
        schedule_notification: false,
      });
      setTimeout(() => {
        setSendNotiSuccessfully(false);
      }, 1200);
      dispatch(setLoadingFalse());
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickDiscardData = () => {
    setSelectedDataClear();
    setFormData({
      title: "",
      content: "",
      actionLink: "",
      schedule_notification: false,
    });
  };

  const isToday = moment().isSame(formData.dateTime, "day");

  const minTime = isToday
    ? moment().add(5, "minutes").toDate()
    : moment().startOf("day").toDate();
  const maxTime = moment().endOf("day").toDate();

  return (
    <div
      style={{ background: "#25252D", padding: "15px" }}
      className="send_notificartion_container"
    >
      {sendNotiSuccessFully && (
        <div className="notification_send_successfully">
          Notification sent successfully
        </div>
      )}

      <div className="title_reset_password">
        {selectNotificationData ? "Edit Notification" : "Create Notification"}
      </div>
      <div className="sub_title">
        All users will receive this notification on their devices.
      </div>
      <div style={{ marginTop: 5 }} className="modal-second-div-title">
        <div style={{}} className="fontSize16">
          Title
        </div>
        <div style={{ marginTop: "5px" }} className="modal-coupon-code-type">
          <input
            className="modal-dropdown"
            type="text"
            placeholder="Enter title"
            value={formData.title}
            onChange={(e) => handleInputChange(e, "title")}
          />
        </div>
      </div>
      <div style={{ marginTop: 5 }} className="modal-second-div-title">
        <div style={{}} className="fontSize16">
          Content
        </div>
        <div style={{ marginTop: "5px" }} className="modal-coupon-code-type">
          <input
            className="modal-dropdown"
            type="text"
            placeholder="Enter content"
            value={formData.content}
            onChange={(e) => handleInputChange(e, "content")}
          />
        </div>
      </div>
      {/* <div style={{ marginTop: 5 }} className="modal-second-div-title">
        <div style={{}} className="fontSize16">
          Action link(optional)
        </div>
        <div style={{ marginTop: "5px" }} className="modal-coupon-code-type">
          <input
            className="modal-dropdown"
            type="text"
            placeholder="Add link"
            value={formData.actionLink}
            onChange={(e) => handleInputChange(e, "actionLink")}
          />
        </div>
      </div> */}

      <div
        className="modal-second-div-title"
        style={{
          flexDirection: "row",
          display: "flex",
          marginTop: 5,
          gap: 10,
        }}
      >
        <Switch
          checked={formData.schedule_notification}
          onChange={(value) => {
            setFormData((prevFormData) => ({
              ...prevFormData,
              schedule_notification: value,
              dateTime: new Date(),
            }));
          }}
          onColor={Colors.darkGreen}
          offColor="#2D2D33"
          disabled={selectNotificationData ? true : false}
          onHandleColor="#818181"
          handleDiameter={20}
          uncheckedIcon={false}
          offHandleColor="#818181"
          checkedIcon={false}
          height={20}
          width={40}
          className="react-switch"
          id="material-switch"
        />
        <div className="fontSize16">Schedule notification</div>
      </div>
      {formData.schedule_notification && (
        <div
          style={{
            marginTop: 10,
            // alignItems: "center",
            // position: "relative",
            flex: 1,
          }}
        >
          <div
            className="data_test"
            style={{
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 10,
              display: "flex",
              // width: "23rem",
              flex: 1,
            }}
            // className="date-picker-border-modal-dropdown"
          >
            <DatePicker
              className="date-picker-border-modal-dropdown"
              selected={new Date(formData.dateTime)}
              onChange={handleDateDateChange}
              showTimeSelect
              timeFormat="hh:mm a"
              timeIntervals={5}
              dateFormat="MMMM d, yyyy h:mm aa"
              minDate={new Date()}
              minTime={minTime}
              popperPlacement="auto"
              popperModifiers={{
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: "viewport",
                },
              }}
              maxTime={maxTime}
              ref={dateInputRef}
              showIcon={false}
              customInput={
                <div
                  style={{
                    border: "1px solid rgba(255, 255, 255, 0.1)",
                    borderRadius: "30px",
                    display: "flex",
                    padding: "8px 12px 8px 12px",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div className="fontSize16">
                    {moment(formData.dateTime).format("DD/MM/YYYY, hh:mm a")}
                  </div>
                  <img
                    src={Icons.ic_calendar}
                    style={{
                      cursor: "pointer",
                      height: "1.2rem",
                      width: "1.2rem",
                    }}
                  />
                </div>
              }
              // icon={
              //   <div style={{}}>
              //   <img
              //     onClick={() => {
              //       if (
              //         dateInputRef &&
              //         dateInputRef.current &&
              //         dateInputRef.current.setFocus
              //       ) {
              //         dateInputRef.current.setFocus();
              //       } else {
              //         console.error(
              //           "dateInputRef or setFocus method is not available."
              //         );
              //       }
              //     }}
              //     className=""
              //     alt=""
              //     style={{ margin: "0", padding: "0", width: "20px", height: "20px", float: "right" }} // Adjust styling as needed
              //     src={Icons.ic_grey_calendar}
              //   />
              //   </div>
              // }
            />
            {/* <img
              onClick={() => {
                if (
                  dateInputRef &&
                  dateInputRef.current &&
                  dateInputRef.current.setFocus
                ) {
                  dateInputRef.current.setFocus();
                } else {
                  console.error(
                    "dateInputRef or setFocus method is not available."
                  );
                }
              }}
              style={{
                cursor: "pointer",
                width: "1.1rem",
                height: "1.1rem",
                // position:"absolute",
                // left:-200
              }}
              alt=""
              src={Icons.ic_grey_calendar}
            /> */}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "2rem",
          gap: 15,
          alignItems: "center",
        }}
      >
        <div
          className="fontSize16"
          style={{
            flex: 0,
            fontWeight: 500,
            border: "1px solid #ffffff10",
            borderRadius: "50px",
            padding: "4px 10px 4px 10px",
            cursor: "pointer",
            fontSize: "0.7rem",
          }}
          onClick={() => onClickDiscardData()}
        >
          Discard
        </div>

        <div
          onClick={() =>
            formData.title != "" &&
            formData.content != "" &&
            onClickSendNotification()
          }
          className="fontSize16"
          style={{
            cursor:
              formData.title != "" && formData.content != ""
                ? "pointer"
                : "default",
            flex: 0,
            fontSize: "0.7rem",
            fontWeight: 500,
            border: "1px solid #ffffff10",
            background:
              formData.title != "" && formData.content != ""
                ? "#FFF"
                : "#FFFFFF1A",
            color:
              formData.title != "" && formData.content != ""
                ? "#1E1E25"
                : "#FFFFFF4D",
            borderRadius: "50px",
            padding: "4px 10px 4px 10px",
          }}
        >
          Send
        </div>
      </div>
    </div>
  );
};

export default CreateNotifiations;
