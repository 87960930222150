import React, { useState } from "react";
import Icons from "../../Constants/Icons";
import CustomDropdown from "../DropDown/CustomDropdown";
import "./Header.css";
import "./../../Constants/CommonStyle.css";

import Colors from "../../Constants/Colors";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
const customStyles = {
  overlay: {
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.3)", // Transparent background
  },
  content: {
    padding: 0, // Remove padding
    margin: 0, // Center horizontally
    maxWidth: "95%", // Optional: set a maximum width for the modal
    width: "100%", // Optional: make the modal responsive
    border: "none", // Remove border (if any)
    background: "transparent", // Transparent background
    display: "flex", // Use flexbox for vertical centering
    alignItems: "flex-end", // Center vertically
    justifyContent: "flex-end", // Center horizontally
  },
};
const data = [
  {
    id: 1,
    header: "Today",
    items: ["Item 1", "Item 2", "Item 3"],
  },
  {
    id: 2,
    header: "yesterday",
    items: ["Item 4", "Item 5"],
  },
  {
    id: 3,
    header: "31-07-2023",
    items: ["Item 6", "Item 7", "Item 8"],
  },
  {
    id: 4,
    header: "31-07-2023",
    items: ["Item 6", "Item 7", "Item 8"],
  },
  {
    id: 5,
    header: "31-07-2023",
    items: ["Item 6", "Item 7", "Item 80"],
  },
];
function Header({ name, transactionId, amount, status }) {
  const navigate = useNavigate();

  const [isMOdalOpen, setIsModalOpen] = useState(false);
  const renderNotificationData = () => {
    return (
      <Modal
        style={customStyles}
        isOpen={isMOdalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className="modalContainer">
          <div
            className="modal-absolute-div"
            onClick={() => setIsModalOpen(false)}
          ></div>
          <div className="modal-parent-div">
            <div
              style={{
                padding: 20,
                display: "flex",
              }}
            >
              <div className="fontSize24">Notification</div>
              <div
                style={{ fontWeight: "400", color: Colors.darkBlue }}
                className="fontSize16WithoutFlex"
              >
                Mark all as read
              </div>
            </div>

            <div
              style={{ maxHeight: "450px" }}
              className="scrollable-container-scrollbar"
            >
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                {data.map((section, index) => (
                  <div style={{ paddingTop: "10px" }} key={index}>
                    <div style={{}} className="fontSize18">
                      {section.header}
                    </div>
                    {section.items.map((item, itemIndex) => (
                      <div key={itemIndex} className="modal-render-item-div">
                        <div>
                          <img
                            src={Icons.ic_default_user}
                            className="modal-user-image"
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <div className="fontSize18">{item}</div>
                          <div className="darkGreyFont16">{"5m"}</div>
                        </div>
                        <div className="modal-white-round"></div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const handleSelect =()=>{
    navigate("/MyAccount")
  }
  
  return (
    <div className="headerContainer">
      <div className="headerFlexContainer">
        <div className="headerText">{name}</div>
        
        <div className="headerIconsContainer">
          {/* <div
            onClick={() => setIsModalOpen(true)}
            style={{ cursor: "pointer", position: "relative" }}
            className="headerNotificationIcon"
          >
            <div className="notification-red-round"></div>
            <img
              className="headerNotificationIconImg"
              alt=""
              src={Icons.ic_notifications}
            />
            
          </div> */}
         
          <div className="headerDropdownContainer">
            <CustomDropdown
              dropDownLabel={"My Account"}
              items={["MyAccount"]}
                onSelect={handleSelect}
            />
          </div>
        </div>
        {renderNotificationData()}
      </div>
    </div>
  );
}

export default Header;
