import React from "react";
import Icons from "../../Constants/Icons";
import TransactionCustomStyle from "./TransactionCustomStyle"; // Import the custom styles

function TransactionList({data,isLast }) {
  return (
    <div
      style={{
        ...TransactionCustomStyle.container,
        borderBottomWidth: isLast ? 0 : 1,
      }}
    >
      <div style={TransactionCustomStyle.listItem}>
        <img
          style={TransactionCustomStyle.userImage}
          alt=""
          src={data?.userImage??Icons.ic_default_user}
        />
        <div style={TransactionCustomStyle.userDetails}>
          <div style={TransactionCustomStyle.userName}>
            <div style={TransactionCustomStyle.transactionMainName}>{data?.userName}</div>
            <div style={TransactionCustomStyle.transactionMainId}>
              <div style={{ position: "relative" ,wordBreak: "break-all",}}>{data?.transactionId}</div>
              <img style={{ cursor: "pointer" }}
                  onClick={() => navigator.clipboard.writeText(data?.transactionId)} className="icon_copy" alt="" src={Icons.ic_copy} />
            </div>
          </div>
          <div
            style={{
              ...TransactionCustomStyle.transactionMainId,
              width: "15%",
              justifyContent: "center",
              display: "flex",
            }}
          >
            {"₹"+data?.totalAmount}
          </div>
          <div
            style={{
              ...TransactionCustomStyle.transactionMainId,
              width: "20%",marginRight:'-20px',
              justifyContent: "center",
              display: "flex",
            }}
          >
            {data?.add_wallet_paymentType_display}
          </div>
          <div
            style={{
              ...TransactionCustomStyle.transactionMainId,
              ...TransactionCustomStyle.transactionStatus,
            }}
          >
            {data?.transaction_status}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TransactionList;
