import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../../Constants/Colors";
import CustomDropdown from "../../../../Components/DropDown/CustomDropdown";
import Icons from "../../../../Constants/Icons";
import Header from "../../../../Components/Header/Header";
import Approvedlist from "./Customelist/Approvedlist";
import CustomPagination from "../../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../../Components/EmptyData/EmptyData";
import {
  getAstrologerApproveOrRejectApi,
  postAstrologerDetails,
  postMoveAstrologerToOnboard,
} from "../../../../Core/Apicall";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../../Config/Actions/loadingActions";
import { useNavigate } from "react-router-dom";

function Approved() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [approvedListData, setApprovedListData] = useState([]);
  const [mainRegstrationListListData, setMainAllRegstrationListListData] =
    useState([]);
  const [totalAstrologer, setTotalAstrologer] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  useEffect(() => {
    onApprovedListApi();
  }, [pageComments]);

  const onApprovedListApi = (selectedPage) => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };
    let data = {
      type: "approved",
    };
    dispatch(setLoadingTrue());
    getAstrologerApproveOrRejectApi(param, data)
      .then((response) => {
        setSearchValue("");
        setTotalAstrologer(Math.ceil(response.total / 100));

        setApprovedListData((prevData) => [...prevData, ...response.data]);
        setMainAllRegstrationListListData((prevData) => [
          ...prevData,
          ...response.data,
        ]);

        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = mainRegstrationListListData.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setApprovedListData(newData);
    } else {
      setApprovedListData(mainRegstrationListListData);
    }
  };
  const handleInputChange = (e) => {
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const onApiOnMoveToOnboard = (item) => {
    let param = {
      astrologerId: item.astrologerId,
    };
    dispatch(setLoadingTrue());
    postMoveAstrologerToOnboard(param)
      .then((response) => {
        setApprovedListData((prevData) =>
          prevData.filter((data) => data.astrologerId !== item.astrologerId)
        );
        setMainAllRegstrationListListData((prevData) =>
          prevData.filter((data) => data.astrologerId !== item.astrologerId)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onCLickEditAstrologer = (item) => {
    let param = {
      astrologerId: item.astrologerId,
    };
    dispatch(setLoadingTrue());
    postAstrologerDetails(param)
      .then((response) => {
        let profileData = response.profileData;
        console.log("profileData", profileData);
        let data = {
          astrologerId: item?.astrologerId,
          userRole: profileData?.userRole ?? "",
          firstName: profileData?.firstName ?? "",
          lastName: profileData?.lastName ?? "",
          displayname: profileData?.userName ?? "",
          email: profileData?.Email ?? "",
          mobileCode: profileData?.mobileCode ?? "+91",
          mobileNumber: profileData?.Mobile ?? "",
          productImage: profileData?.profileUrl ?? "",
          gender: profileData?.Gender ?? "",
          experience: profileData?.Experiance ?? "",
          expertise: profileData?.Expertise ?? [],
          bio: profileData?.About ?? "",
          aadharNumber:
            profileData?.adharCardDetails?.adharCardNumber ?? undefined,
          aadharImageFrontSide:
            profileData?.adharCardDetails?.adharCardFrontImage ?? "",
          aadharImageBackSide:
            profileData?.adharCardDetails?.adharCardBackImage ?? "",
          pancardNumber: profileData?.panCardDetails?.panNumber ?? "",
          panCardImage: profileData?.panCardDetails?.panImage ?? "",
          accountHolderName: profileData?.bankDetails?.accountHolderName ?? "",
          bankAccountNumber: profileData?.bankDetails?.accountNumber ?? "",
          ifscCodeNumber: profileData?.bankDetails?.IFSCCode ?? "",
          bankName: profileData?.bankDetails?.bankName ?? "",
          priceperminut: profileData?.OldPrice ?? "",
          astrologercharge: profileData?.astrologerCharge ?? "",
          zigzekmargin: profileData?.zigzakMargin ?? "",
          marginpercentage: profileData?.zigzakMarginInPercentage ?? "",
          discountprice: profileData?.NewPrice ?? "",
          discount_percentage: profileData?.discountInPercentage ?? "",
        };
        const dataEdit = true;
        navigate("/AddAstrologer", { state: { data, dataEdit } });
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        console.log("errerr", err);
        dispatch(setLoadingFalse());
      });
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalAstrologer > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers /Approved"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologers-list-parent">
          <div className="fontSize24">Astrologers list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            alignSelf: "stretch",
            flex: 1,
            border: Colors.darkBorderColor,
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: Colors.borderColor,
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: 1,
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <div style={{ width: "20%" }} className="contentName">
                Registration ID
              </div>
              <div style={{ width: "16%" }} className="contentName">
                Name
              </div>
              <div style={{ width: "22%" }} className="contentName">
                Contact
              </div>
              <div style={{ width: "9%" }} className="contentName">
                Experience
              </div>
              <div style={{ width: "24%" }} className="contentName">
                Expertise
              </div>
              <div style={{ width: "7%" }} className="contentName">
                Gender
              </div>
              <div style={{ width: "2%" }}></div>
            </div>
          </div>
          <Approvedlist
            handleScroll={handleScroll}
            commentsContainerRef={commentsContainerRef}
            onEditAstrologer={onCLickEditAstrologer}
            onMoveToOnboard={onApiOnMoveToOnboard}
            data={approvedListData}
          />
        </div>
        {approvedListData.length <= 0 && <EmptyData />}
        {/* {approvedListData.length != 0 && (
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              pageCount={totalAstrologer}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={onApprovedListApi}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Approved;
