import { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./AstrologerProfile.css";
import "./../../../Constants/CommonStyle.css";
import Icons from "../../../Constants/Icons";
import AstrologerProfileTab from "./CustomeScreen/AstrologerProfileTab";
import TransactionContentTitle from "../../../Components/CustomTransactionScreen/TransactionContentTitle";
import AstrologerSessionTab from "./CustomeScreen/AstrologerSessionTab";
import Header from "../../../Components/Header/Header";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  getAstrologerTransactionHistoryList,
  getAstrologerUserDetails,
  getAstrologerWithdrawalHistoryList,
  onApiCallAstrologerActiveInactive,
} from "../../../Core/Apicall";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import CustomWithdrawalTitle from "../../../Components/CustomWithdrawal/CustomWithdrawalTitle";
import StatusButton from "../../../Components/StatusButton/StatusButton";

const AstrologerProfile = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { astrologerId } = location.state;

  const [getAstrologerId, setGetAstrologerId] = useState(astrologerId);
  const tabs = ["Profile", "Sessions", "Wallet transactions", "Withdrawals"];
  const [activeTab, setActiveTab] = useState(0);
  const [astrologerProfileData, setAstrologerProfileData] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [withdrawalsHistoryData, setWithdrawalHistoryData] = useState([]);
  const [
    totalTransactionHistoryPageCount,
    setTotalTransactionHistoryPageCount,
  ] = useState(0);
  const [totalWithdrawalHistoryPageCount, setTotalWithdrawalHistoryPageCount] =
    useState(0);
  const sortByItem = ["Date & Time"];
  const [dropdownSelected, setDropDownSelectedValue] = useState("By Status");
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);
  const commentsSecondContainerRef = useRef(null);
  useEffect(() => {
    dispatch(setLoadingTrue());
    getAstrologerUserDetails({ astrologerId: getAstrologerId })
      .then((response) => {
        setAstrologerProfileData(response.profileData);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  }, []);

  useEffect(() => {
    if (activeTab == 2) {
      onClickTransactionHistoryApi();
    } else if (activeTab == 3) {
      onClickWithdrawalHostoryApi();
    }
  }, [activeTab, pageComments]);

  const onClickWithdrawalHostoryApi = () => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };

    let data = {
      astrologerId: getAstrologerId,
    };
    dispatch(setLoadingTrue());
    getAstrologerWithdrawalHistoryList(param, data)
      .then((response) => {
        console.log("testtesds", response);
        setWithdrawalHistoryData((prevData) => [...prevData, ...response.data]);

        setTotalWithdrawalHistoryPageCount(
          Math.ceil(response.totalTransactionsCount / 100)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };
  const onClickTransactionHistoryApi = () => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };

    let data = {
      astrologerId: getAstrologerId,
    };
    dispatch(setLoadingTrue());
    getAstrologerTransactionHistoryList(param, data)
      .then((response) => {
        setDropDownSelectedValue("By Status");
        setTransactionHistoryData((prevData) => [
          ...prevData,
          ...response.data,
        ]);

        setTotalTransactionHistoryPageCount(
          Math.ceil(response.totalTransactionsCount / 100)
        );
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleTabClick = (index) => {
    setPageComments(0);
    setWithdrawalHistoryData([]);
    setTransactionHistoryData([]);
    setActiveTab(index);
  };

  const handleScroll = () => {
    console.log("ssddsd");
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (activeTab == 2) {
        if (totalTransactionHistoryPageCount > pageComments + 1) {
          setPageComments(pageComments + 1);
        }
      } else {
        if (totalWithdrawalHistoryPageCount > pageComments + 1) {
          setPageComments(pageComments + 1);
        }
      }
    }
  };

  const parseCustomDate = (dateStr) => {
    const parts = dateStr.split(" ");
    const [date, time] = parts;
    const [day, month] = date.split("-");
    const [hours, minutes] = time.split(":");
    const isAM = parts[2] === "AM";
    const year = new Date().getFullYear();
    const parsedDate = new Date(year, month - 1, day, hours, minutes);
    if (!isAM && parsedDate.getHours() < 12) {
      parsedDate.setHours(parsedDate.getHours() + 12);
    }
    return parsedDate;
  };

  const sortByDuration = (property) => {
    setDropDownSelectedValue("By Status");
    if (property === "Date & Time") {
      const sortedData = [...transactionHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");

      sortedData.sort((a, b) => {
        const dateA = new Date(a.date_and_time);
        const dateB = new Date(b.date_and_time);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setTransactionHistoryData(sortedData);
    }
  };
  const sortByWithdrawalDuration = (property) => {
    if (property === "Date & Time") {
      const sortedData = [...withdrawalsHistoryData];
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      sortedData.sort((a, b) => {
        const dateA = new Date(a.dateAndTime);
        const dateB = new Date(b.dateAndTime);
        if (sortOrder === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });

      setWithdrawalHistoryData(sortedData);
    }
  };

  const sortByAMount = (property) => {
    const sortedData = [...transactionHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setTransactionHistoryData(sortedData);
  };
  const sortByAmountWithdrawal = (property) => {
    const sortedData = [...withdrawalsHistoryData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[property] - b[property];
      } else {
        return b[property] - a[property];
      }
    });
    setWithdrawalHistoryData(sortedData);
  };

  const onClickActiveInActiveAstrologer = async () => {
    console.log("Dfdfdds", astrologerProfileData?.astrologerId);

    try {
      dispatch(setLoadingTrue());
      let param = {
        astrologerId: astrologerProfileData?.astrologerId,
        type: astrologerProfileData.isActive ? "inActive" : "active",
      };
      let resp = await onApiCallAstrologerActiveInactive(param);
      if (resp.status) {
        getAstrologerUserDetails({ astrologerId: getAstrologerId })
          .then((response) => {
            setAstrologerProfileData(response.profileData);
            dispatch(setLoadingFalse());
          })
          .catch((err) => {
            dispatch(setLoadingFalse());
          });
      }
    } catch (error) {
      console.log("error", error);
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers / " + astrologerProfileData?.userName} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          flex: 1,
          alignSelf: "stretch",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              flex: 0.45,
              display: "flex",
              alignSelf: "stretch",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1.5rem",
            }}
          >
            <div
              style={{
                borderRadius: "87px",
                backgroundColor: "#25252d",
                width: "5rem",
                height: "5rem",
                overflow: "hidden",
                flexShrink: "0",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <img
                style={{
                  width: "5rem",
                  height: "5rem",
                  objectFit: "cover",
                }}
                alt=""
                src={
                  astrologerProfileData?.profileUrl ??
                  Icons.ic_man_large_default
                }
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "0.1rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                <div
                  style={{ wordBreak: "break-all",  }}
                  className="fontSize24"
                >
                  {astrologerProfileData?.userName}
                </div>
                <div
                  className="fontSize14"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.25rem",
                    color:
                      astrologerProfileData?.userStatus === "online"
                        ? Colors.darkGreen
                        : astrologerProfileData?.userStatus === "away"
                        ? Colors.darkOrange
                        : Colors.darkRed,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor:
                          astrologerProfileData?.userStatus === "online"
                            ? Colors.darkGreen
                            : astrologerProfileData?.userStatus === "away"
                            ? Colors.darkOrange
                            : Colors.darkRed,
                        filter: "blur(9px)",
                        width: "0.38rem",
                        height: "0.38rem",
                        opacity: "0.5",
                      }}
                    />
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "50%",
                        backgroundColor:
                          astrologerProfileData?.userStatus === "online"
                            ? Colors.darkGreen
                            : astrologerProfileData?.userStatus === "away"
                            ? Colors.darkOrange
                            : Colors.darkRed,
                        width: "0.38rem",
                        height: "0.38rem",
                        marginLeft: "-0.37rem",
                      }}
                    />
                  </div>

                  <div style={{ position: "relative" }}>
                    {astrologerProfileData?.userStatus}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="fontSize16"
                  style={{ color: Colors.greyColor50 }}
                >
                  {astrologerProfileData?.userRole}
                </div>
                <div
                  className="fontSize16"
                  style={{ color: Colors.greyColor50 }}
                >
                  {astrologerProfileData?.location}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      width: "1.2rem",
                      objectFit: "contain",
                    }}
                    alt=""
                    src={Icons.ic_white_wallet}
                  />
                  <div
                    className="fontSize16"
                    style={{ textAlign: "center", color: Colors.white }}
                  >
                    {astrologerProfileData?.totalWalletBalance.toFixed(2)}
                  </div>
                </div>

                <div
                  style={{
                    cursor: "pointer",
                    marginTop: "10px",
                    // width: "auto",
                  }}
                  onClick={() => onClickActiveInActiveAstrologer()}
                >
                  <StatusButton
                    style={{
                      width: "auto",
                      padding: "10px 20px 10px 20px",
                      backgroundColor: astrologerProfileData?.isActive
                        ? Colors.mainGreen01
                        : Colors.mainRed01,
                      borderColor: astrologerProfileData?.isActive
                        ? Colors.darkGreen
                        : Colors.darkRed,
                      color: astrologerProfileData?.isActive
                        ? Colors.darkGreen
                        : Colors.darkRed,
                    }}
                  >
                    {astrologerProfileData?.isActive ? "Active" : "Inactive"}
                  </StatusButton>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "1rem",
              flex: 0.7,
            }}
          >
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Sessions fees
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <span>₹{astrologerProfileData?.sessionPrice ?? 0}</span>
                <span style={{ fontSize: "1rem" }}>/min</span>
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Avg. session duration
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                ₹{astrologerProfileData?.avgSessionDuration ?? 0}
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Ratings
              </div>
              <div
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                <img
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                  }}
                  alt=""
                  src={Icons.ic_star}
                />
                <div
                  className="fontSize24"
                  style={{ marginLeft: "5px", position: "relative" }}
                >
                  {astrologerProfileData?.Rating}
                  {astrologerProfileData?.ReviewCount == undefined
                    ? "(0)"
                    : "(" + astrologerProfileData?.ReviewCount + ")"}
                </div>
              </div>
            </div>
            <div className="mainContainerSessionView">
              <div className="fontSize16" style={{ color: Colors.greyColor50 }}>
                Total earnings
              </div>
              <div
                className="fontSize24"
                style={{
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  display: "flex",
                  position: "relative",
                }}
              >
                ₹{astrologerProfileData?.totalEarning ?? 0}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            boxSizing: "border-box",
            gap: "1rem",
            alignSelf: "stretch",
            borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                fontWeight: "400",
                color:
                  index != activeTab ? "rgba(255, 255, 255, 0.5)" : " #fff",
                borderBottom: index != activeTab ? "" : "1px solid #fff",
                cursor: "pointer",
              }}
              className="fontSize18"
              onClick={() => handleTabClick(index)}
            >
              {tab}
            </div>
          ))}
        </div>

        {activeTab === 0 && (
          <AstrologerProfileTab astrologerProfileData={astrologerProfileData} />
        )}
        {activeTab === 1 && (
          <AstrologerSessionTab
            onClickSession={false}
            astrologerProfileData={astrologerProfileData}
          />
        )}
        {(activeTab === 2 || activeTab == 3) && (
          <div
            style={{
              display: "flex",
              gap: "1rem",
              flexDirection: "column",
              alignSelf: "stretch",
            }}
          >
            <div style={{}} className="sectionDivStyle">
              <div
                className="title24FontWeight400WHite"
                style={{
                  flex: "1",
                  position: "relative",
                  alignSelf: "stretch",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {activeTab === 2 ? "Transaction history" : "Withdrawals"}
              </div>
              <div style={{}} className="">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "1rem",
                    fontSize: "1rem",
                    color: "rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "8px",
                      border: Colors.darkBorderColor,
                      boxSizing: "border-box",
                      width: "8.38rem",
                      display: "flex",
                      zIndex: 1,
                      padding: "0.5rem 0.5rem",
                    }}
                  >
                    <CustomDropdown
                      dropDownLabel={"Sort by"}
                      items={sortByItem}
                      color="blue"
                      onSelect={sortByDuration}
                      customLabelStyle={{
                        fontWeight: "400",
                        color: Colors.greyColor50,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {activeTab == 3 ? (
              <CustomWithdrawalTitle
                handleScroll={handleScroll}
                commentsContainerRef={commentsContainerRef}
                data={withdrawalsHistoryData}
                dateTimeSort={() => sortByWithdrawalDuration("Date & Time")}
                amountSort={() => sortByAmountWithdrawal("totalPaidAmount")}
              />
            ) : (
              <TransactionContentTitle
                handleScroll={handleScroll}
                commentsContainerRef={commentsContainerRef}
                data={transactionHistoryData}
                fromHide={activeTab === 2 ? false : true}
                toHide={activeTab === 2 ? false : true}
                transactionID={"Transaction ID"}
                from={"From"}
                to={"To"}
                dateTimeSort={() => sortByDuration("Date & Time")}
                amountSort={() => sortByAMount("amount")}
                dateTime={"Date & Time"}
                transactionTypeHide={activeTab === 2 ? false : true}
                transactionType={"Transaction for"}
                amount={"Amount"}
                status={"Status"}
              />
            )}
            {/* {activeTab == 3 && withdrawalsHistoryData.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={totalWithdrawalHistoryPageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={onClickWithdrawalHostoryApi}
                />
              </div>
            )} */}
            {/* {activeTab == 2 && transactionHistoryData.length != 0 && (
              <div
                style={{
                  alignItems: "flex-end",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <CustomPagination
                  pageCount={totalTransactionHistoryPageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={onClickTransactionHistoryApi}
                />
              </div>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default AstrologerProfile;
