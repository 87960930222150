import { useEffect, useRef, useState } from "react";
import "./../../../Constants/CommonStyle.css";
import "./SendNotification.css";
import { useDispatch } from "react-redux";
import { getApiHistoryNotificationList } from "../../../Core/Apicall";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import moment from "moment";

const HistoryNotifications = () => {
  const dispatch = useDispatch();
  const [historyList, setHistoryList] = useState([]);
  const [totalPaginationOrder, setTotalPaginationOrder] = useState(0);
  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  useEffect(() => {
    onHistoryApiCallList();
  }, [pageComments]);

  const onHistoryApiCallList = async () => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };
    try {
      dispatch(setLoadingTrue());
      const response = await getApiHistoryNotificationList(param);
      setHistoryList((prevData) => [...prevData, ...response?.data]);
      setTotalPaginationOrder(Math.ceil(response.totalNotificationCount / 100));
      dispatch(setLoadingFalse());
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const renderCouponContent = () => {
    return (
      <div className="list-user-div-parent-container-coupon">
        {/* <div
          style={{
            width: "30%",
          }}
          className="fontSize16WithoutFlex"
        >
          Id
        </div> */}
        <div
          style={{
            width: "40%",
          }}
          className="fontSize16WithoutFlex"
        >
          Title
        </div>
        <div
          style={{
            width: "40%",
          }}
          className="fontSize16WithoutFlex"
        >
          Content
        </div>
        <div
          style={{
            width: "20%",
          }}
          className="fontSize16WithoutFlex"
        >
          Date & Time
        </div>
        <div style={{ width: "1.1px" }}></div>
      </div>
    );
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalPaginationOrder > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  const renderCouponList = () => {
    return (
      <div
        style={{ maxHeight: "500px" }}
        ref={commentsContainerRef}
        onScroll={handleScroll}
        className="scrollable-container"
      >
        {historyList.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  alignSelf: "stretch",
                  padding: "1rem",
                  boxSizing: "border-box",
                  gap: "0.5rem",
                }}
              >
                {/* <div
                  style={{
                    width: "30%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 5 }}
                  >
                    <div
                      style={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "0.8rem",
                        fontWeight: 400,
                        color: "#fff",
                      }}
                    >
                      {item?._id}
                    </div>
                    <img
                      style={{
                        position: "relative",
                        width: "1.13rem",
                        height: "1.13rem",
                        overflow: "hidden",
                        flexShrink: "0",
                      }}
                      alt=""
                      src={Icons.ic_link}
                    />
                 </div>
                  <div
                    style={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "0.67rem",
                      fontWeight: 400,
                      color: "#FFFFFF80",
                    }}
                  >
                    {item?.content}
                  </div>
                 </div> */}
                <div
                  style={{
                    width: "40%",
                  }}
                  className="itemTextDisplay"
                >
                  {item?.title}
                </div>

                <div
                  style={{
                    width: "40%",
                  }}
                  className="itemTextDisplay"
                >
                  {item?.content}
                </div>

                <div
                  style={{
                    width: "20%",
                  }}
                  className="itemTextDisplay"
                >
                  {moment(item?.date_and_time).format("DD-MM-YYYY, hh:mm a")}
                </div>
              </div>
              <div className="borderTopStyle" />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div style={{ alignSelf: "stretch" }} className="">
      <div style={{ alignSelf: "stretch" }} className="list-user-div-container">
        {renderCouponContent()}
        {renderCouponList()}
      </div>
      {historyList?.length == 0 && <EmptyData />}
      {/* {historyList.length != 0 && (
        <div
          style={{
            alignItems: "flex-end",
            display: "flex",
            flex: 1,
            alignSelf: "stretch",
            justifyContent: "flex-end",
          }}
        >
          <CustomPagination
            pageCount={totalPaginationOrder}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            onPageChange={onHistoryApiCallList}
          />
        </div>
      )} */}
    </div>
  );
};

export default HistoryNotifications;
