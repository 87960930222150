// Home.js
import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./AstrologerStyle.css";
import "./../../../Constants/CommonStyle.css";
import CustomDropdown from "../../../Components/DropDown/CustomDropdown";
import Icons from "../../../Constants/Icons";
import AstrologerRenderList from "./CustomeScreen/AstrologerRenderList";
import CustomPagination from "../../../Components/CustomPagination/CustomPagination";
import Header from "../../../Components/Header/Header";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import { getAllAstrologerList } from "../../../Core/Apicall";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import { useNavigate } from "react-router-dom";
function Astrologer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = ["All", "Live", "Consulting",  "Away", "Online"];
  const [astrologersData, setAstrologersData] = useState([]);
  const [mainAllAstrologerList, setMainAllAstrologerLis] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [totalAstrologer, setTotalAstrologer] = useState(0);
  const [totalBusyAstrologers, setTotalBusyAstrologers] = useState(0);
  const [totalOfflineAstrologers, setTotalOfflineAstrologers] = useState(0);
  const [totalOnlineAstrologers, setTotalOnlineAstrologers] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [dropdownSelected, setDropDownSelectedValue] = useState("By Status");

  const [pageComments, setPageComments] = useState(0);
  const commentsContainerRef = useRef(null);

  useEffect(() => {
    onClickAstrologerMainListApi();
  }, [pageComments]);

  const onClickAstrologerMainListApi = (selectedPage) => {
    let param = {
      offset: 100 * pageComments,
      limit: 100,
    };
    dispatch(setLoadingTrue());
    getAllAstrologerList(param)
      .then((response) => {
        console.log("respdssdonse", response);
        setSearchValue("");
        setDropDownSelectedValue("By Status");
        // setAstrologersData(response.AstrologerProfilesData);
        // setMainAllAstrologerLis(response.AstrologerProfilesData);

        setAstrologersData((prevData) => [
          ...prevData,
          ...response.AstrologerProfilesData,
        ]);
        setMainAllAstrologerLis((prevData) => [
          ...prevData,
          ...response.AstrologerProfilesData,
        ]);

        setTotalAstrologer(Math.ceil(response.totalAstrologer / 100));
        setTotalBusyAstrologers(response.totalBusyAstrologers);
        setTotalOfflineAstrologers(response.totalOfflineAstrologers);
        setTotalOnlineAstrologers(response.totalOnlineAstrologers);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const handleSelect = (userStatus) => {
    setSearchValue("");
    setDropDownSelectedValue(userStatus);
    if (userStatus === "All") {
      setAstrologersData(mainAllAstrologerList); // Show all items
    } else {
      const statusMap = {
        Live: "live",
        Consulting: "consulting",
        Canceled: "cancel",
        Away: "away",
        Online: "online",
      };

      const filteredData = mainAllAstrologerList.filter(
        (astrologer) => astrologer.userStatus === statusMap[userStatus]
      );
      setAstrologersData(filteredData);
      // }

      // const filteredData = mainAllAstrologerList.filter(
      //   (astrologer) => astrologer.userStatus === status
      // );
      // setAstrologersData(filteredData);
    }
  };
  const parseAvgTime = (time) => {
    const [value] = time.split(" ");
    const numericValue = parseFloat(value);
    return numericValue;
  };

  const onCLickSortingArray = (property) => {
    const sortedData = [...astrologersData];
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    if (property == "avg") {
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return parseAvgTime(a[property]) - parseAvgTime(b[property]);
        } else {
          return parseAvgTime(b[property]) - parseAvgTime(a[property]);
        }
      });
      setAstrologersData(sortedData);
    } else {
      sortedData.sort((a, b) => {
        if (sortOrder === "asc") {
          return a[property] > b[property] ? 1 : -1;
        } else {
          return a[property] < b[property] ? 1 : -1;
        }
      });
      setAstrologersData(sortedData);
    }
  };

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = mainAllAstrologerList.filter((item) => {
        const keys = Object.keys(item);
        const textData = text.toUpperCase();
        return keys.some((key) => {
          const itemData = item[key]
            ? item[key].toString().toUpperCase()
            : "".toUpperCase();
          return itemData.indexOf(textData) > -1;
        });
      });
      setAstrologersData(newData);
    } else {
      setAstrologersData(mainAllAstrologerList);
    }
  };
  console.log("astrologersData", astrologersData);
  const handleInputChange = (e) => {
    setDropDownSelectedValue("By Status");
    const searchText = e.target.value;
    setSearchValue(searchText);
    searchFilterFunction(searchText);
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      commentsContainerRef?.current;
    if (scrollTop + clientHeight + 1 > scrollHeight) {
      if (totalAstrologer > pageComments + 1) {
        setPageComments(pageComments + 1);
      }
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
              {/* <div
                style={{
                  cursor: "pointer",
                  background: Colors.white,
                  padding: "7px 10px 7px 10px",
                  gap: "0.5rem",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => navigate("/AddAstrologer")}
              >
                <img
                  src={Icons.ic_add}
                  style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
                />
                <div className="darkGreyFont16" style={{ color: Colors.black }}>
                  Add astrologer
                </div>
              </div> */}
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total astrologers</div>
                <div className="fontSize24">{totalAstrologer}</div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total online</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {totalOnlineAstrologers}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total busy</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {totalBusyAstrologers}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total offline</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {totalOfflineAstrologers}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="astrologers-list-parent">
          <div className="fontSize24">Astrologers list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  borderRadius: "8px",
                  border: Colors.darkBorderColor,
                  boxSizing: "border-box",
                  width: "9.38rem",
                  display: "flex",
                  zIndex: 1,
                  padding: "0.5rem 0.5rem",
                }}
              >
                <CustomDropdown
                  dropDownLabel={dropdownSelected}
                  items={items}
                  color="blue"
                  onSelect={handleSelect}
                  customLabelStyle={{
                    fontWeight: "400",
                    color: Colors.greyColor50,
                  }}
                />
              </div>

              <div
                style={{
                  borderRadius: "8px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  boxSizing: "border-box",
                  width: "15.75rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  padding: "0.5rem 0.5rem",
                  gap: "0.5rem",
                }}
              >
                <img
                  style={{
                    position: "relative",
                    width: "1.5rem",
                    height: "1.5rem",
                    objectFit: "contain",
                  }}
                  alt=""
                  src={Icons.ic_search}
                />
                <input
                  type="text"
                  className="searchTextInput"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="frame-parent3">
          <div
            style={{
              justifyContent: "flex-start",
              alignSelf: "stretch",
              flex: 1,
            }}
            className="name-parent"
          >
            <div
              style={{
                width: "20%",
              }}
              className="fontSize16WithoutFlex"
            >
              Name
            </div>
            <div
              onClick={() => onCLickSortingArray("Rating")}
              style={{
                justifyContent: "flex-start",
                cursor: "pointer",
                width: "10%",
              }}
              className="call-sessions-parent"
            >
              <div className="fontSize16WithoutFlex">Ratings</div>
              <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
            </div>

            <div
              onClick={() => onCLickSortingArray("totalSession")}
              style={{
                width: "8%",
                cursor: "pointer",
                justifyContent: "flex-start",
              }}
              className="total-sessions-parent"
            >
              <div style={{}} className="fontSize16WithoutFlex">
                Fees
              </div>
              <img
                style={{}}
                className="ic_sorting"
                alt=""
                src={Icons.ic_shorting}
              />
            </div>
            <div
              onClick={() => onCLickSortingArray("averageTimeSpend")}
              style={{
                cursor: "pointer",
                width: "17%",
                justifyContent: "center",
              }}
              className="call-sessions-parent"
            >
              <div style={{}} className="fontSize16WithoutFlex">
                Avg. time spends
              </div>
              <img className="ic_sorting" alt="" src={Icons.ic_shorting} />
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                width: "35%",
              }}
              className="fontSize16WithoutFlex"
            >
              Expertise
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                width: "10%",
              }}
              className="fontSize16WithoutFlex"
            >
              Status
            </div>
            {/* <div style={{ width: "3px" }}></div> */}
          </div>
          <div
            style={{ maxHeight: "500px" }}
            onScroll={handleScroll}
            ref={commentsContainerRef}
            className="scrollable-container"
          >
            {astrologersData.map((session, index) => (
              <AstrologerRenderList
                key={index}
                astrologerId={session.astrologerId}
                name={session.userName}
                profession={session.userRole}
                ratings={session.Rating}
                session_type={session.session_type}
                chatsessions={session.chatSessionCount}
                price={session.totalEarning}
                status={session.userStatus}
                expertise={session.expertise}
                avg={session.averageTimeSpend}
                liveSession={session.liveSessionCount}
                totalSession={session.totalSession}
                callsessions={session.callSessionCount}
                profileUrl={session.profileUrl}
                session={session}
              />
            ))}
          </div>
        </div>
        {astrologersData.length <= 0 && <EmptyData />}
        {/* {astrologersData.length != 0 && (
          <div
            style={{
              alignItems: "flex-end",
              display: "flex",
              flex: 1,
              alignSelf: "stretch",
              justifyContent: "flex-end",
            }}
          >
            <CustomPagination
              pageCount={totalAstrologer}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={onClickAstrologerMainListApi}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}

export default Astrologer;
