import Colors from "../../../../Constants/Colors";
import "./../../../../Constants/CommonStyle.css";
import Icons from "../../../../Constants/Icons";
import "./UserProfileTab.css";

function UserProfileTab({ userProfileData }) {
  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
        alignSelf: "stretch",
      }}
    >
      <div className="profile-tab-custom-container">
        <div style={{ padding: "1rem" }} className="fontSize20">
          Personal information
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "0.75rem 1rem",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <div
                className="fontSize16"
                style={{
                  fontWeight: 400,
                  color: Colors.greyColor50,
                  position: "relative",
                }}
              >
                ID
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.5rem",
                }}
              >
                <div className="fontSize18">{userProfileData?.userId}</div>
                <img
                  onClick={() =>
                    navigator.clipboard.writeText(userProfileData?.userId)
                  }
                  style={{
                    position: "relative",
                    width: "1.2rem",
                    objectFit: "contain",
                    cursor:"pointer",
                    height: "1.2rem",
                    flexShrink: "0",
                  }}
                  alt=""
                  src={Icons.ic_copy}
                />
              </div>
            </div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Full name
            </div>
            <div className="fontSize18">{userProfileData?.userName}</div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              position: "relative",
              borderTop: "1px solid rgba(255, 255, 255, 0.1)",
              boxSizing: "border-box",
              height: "0.06rem",
            }}
          />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Email
            </div>
            <div className="fontSize18">{userProfileData?.email}</div>
          </div>
          <div className="borderTopStyle" />
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Phone
            </div>
            <div className="fontSize18">{userProfileData?.mobile}</div>
          </div>
        </div>
      </div>

      <div className="profile-tab-custom-container">
        <div style={{ padding: "1rem" }} className="fontSize20">
          Birth details
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Birth date
            </div>
            <div className="fontSize18">{userProfileData?.birthDate==""?'-':userProfileData?.birthDate}</div>
          </div>
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Birth time
            </div>
            <div className="fontSize18">{userProfileData?.birthTime==""?"-":userProfileData?.birthTime}</div>
          </div>
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Birth place
            </div>
            <div className="fontSize18">{userProfileData?.birthPlace ==""?"-" :userProfileData?.birthPlace}</div>
          </div>
        </div>
      </div>
      <div className="profile-tab-custom-container">
        <div style={{ padding: "1rem" }} className="fontSize20">
          Languages
        </div>
        <div
          style={{
            alignSelf: "stretch",
          }}
        >
          <div className="mainPersonalInformationContainer">
            <div
              className="fontSize16"
              style={{
                fontWeight: 400,
                color: Colors.greyColor50,
                position: "relative",
              }}
            >
              Selected languages
            </div>
            <div className="fontSize18">
              {userProfileData?.languages.map((item, index) => {
                return (
                  <span>
                    {" "}
                    {item.language}
                    {index !== userProfileData.languages.length - 1 && ", "}
                  </span>
                );
              })}{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserProfileTab;
