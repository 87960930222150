import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import Header from "../../../Components/Header/Header";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  onAPiCallGetAllBanners,
  onApiCallInActiveBanner,
} from "../../../Core/Apicall";
import BannerListItem from "./BannerListItem";
import EmptyData from "../../../Components/EmptyData/EmptyData";
import Icons from "../../../Constants/Icons";

function BannerList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    onBannerListApi();
  }, []);
  const onBannerListApi = () => {
    dispatch(setLoadingTrue());
    onAPiCallGetAllBanners()
      .then((response) => {
        setBannerList(response?.Banners);
        dispatch(setLoadingFalse());
      })
      .catch((err) => {
        dispatch(setLoadingFalse());
      });
  };

  const onclickAddBanner = () => {
    const event = new CustomEvent("subTabEventListner", {
      detail: "/AddBanner",
    });
    window.dispatchEvent(event);
    navigate("/AddBanner");
  };

  const onClickEditBanner = (item) => {
    console.log("itemitem", item);

    let data = {
      bannerImage: item?.imageUrl,
      type:
        item?.bannerType == "astrologer"
          ? "Astrologer"
          : item?.bannerType == "shop"
          ? "Shop"
          : item?.bannerType == "live"
          ? "live"
          : "Wallet",
      astrologerPer: item?.discountPercentage ?? "",
      bannerId: item._id,
      title: item.title,
      shop_category:item?.categoryId
    };
    const dataEdit = true;

    const event = new CustomEvent("subTabEventListner", {
      detail: "/AddBanner",
    });
    window.dispatchEvent(event);
    navigate("/AddBanner", { state: { data, dataEdit } });

    dispatch(setLoadingFalse());
  };

  const onClickInActiveBanner = async (item) => {
    let param = {
      bannerId: item._id,
      isActive: item.isActive ? false : true,
    };
    dispatch(setLoadingTrue());
    try {
      const res = await onApiCallInActiveBanner(param);
      setBannerList((prevData) =>
        prevData.map((data) =>
          data._id === item._id ? { ...data, isActive: !item.isActive } : data
        )
      );
      dispatch(setLoadingFalse());
    } catch (error) {
      dispatch(setLoadingFalse());
    }
  };

  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Astrologers / New registration"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          marginTop: "10px",
        }}
      >
        <div className="astrologers-list-parent">
          <div className="fontSize24">Banner list</div>
          <div className="frame-parent2">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                fontSize: "1rem",
                color: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  background: Colors.white,
                  padding: "7px 10px 7px 10px",
                  gap: "0.5rem",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  onclickAddBanner();
                }}
              >
                <img
                  src={Icons.ic_add}
                  style={{ height: "1rem", width: "1rem", objectFit: "cover" }}
                />
                <div className="darkGreyFont16" style={{ color: Colors.black }}>
                  Add Banner
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
          marginTop: "10px",
        }}
      >
        {/* <div className="astrologers-list-parent">
          <div className="fontSize24">Banner list</div>
        </div> */}
        <div
          style={{
            alignSelf: "stretch",
            flex: 1,
            border: Colors.darkBorderColor,
            borderRadius: "8px",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              backgroundColor: Colors.borderColor,
              borderBottom: Colors.darkBorderColor,
              alignSelf: "stretch",
              flex: 1,
              padding: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <div style={{ width: "25%" }} className="contentName">
                Banner ID
              </div>
              <div style={{ width: "10%" }} className="contentName">
                Type
              </div>
              <div style={{ width: "18%" }} className="contentName">
                Title
              </div>
              <div style={{ width: "10%" }} className="contentName">
                Percentage
              </div>
              <div style={{ width: "20%" }} className="contentName">
                Image
              </div>
              <div style={{ width: "15%" }} className="contentName"></div>
              <div style={{ width: "2%" }}></div>
              <div style={{ width: "1px" }}></div>
            </div>
          </div>

          <BannerListItem
            onClickEditBanner={onClickEditBanner}
            data={bannerList}
            onClickInActiveBanner={onClickInActiveBanner}
          />
        </div>
        {bannerList.length == 0 && <EmptyData />}
      </div>
    </div>
  );
}

export default BannerList;
