import Colors from "../../Constants/Colors";

const SessionCustomStyle = {
  container: {
    alignSelf: "stretch",
    flex: "1",
    display: "flex",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "1rem",
  },
  transactionMainId: {
    width: "28%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "0.5rem",
    fontSize: "0.88rem",
    color: Colors.greyColor50,
  },

  amount: {
    width: "13%",
  },
  duration: {
    width: "15%",
  },
  status: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    fontSize: "0.75rem",
    color: Colors.darkBlue,
    width: "20%",
  },
  statusBadge: {
    borderRadius: "46px",
    backgroundColor: "rgba(46, 144, 250, 0.2)",
    height: "2rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0.38rem 1rem",
    boxSizing: "border-box",
  },
  statusText: {
    position: "relative",
    fontWeight: "600",
  },
  sessionDetails: {
    position: "relative",
    display: "inline-block",
    fontFamily: "Poppins-Regular",
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#fff",
  },
};

export default SessionCustomStyle;
