// Home.js
import React, { useEffect, useRef, useState } from "react";
import Colors from "../../../Constants/Colors";
import "./../../../Constants/CommonStyle.css";
import Header from "../../../Components/Header/Header";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import Icons from "../../../Constants/Icons";
import ShopOrderList from "./CustomComponent/ShopOrderList";
import { useNavigate } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../Config/Actions/loadingActions";
import {
  getApiAllOrderList,
  post_api_main_overview,
} from "../../../Core/Apicall";
import { useDispatch } from "react-redux";
import EmptyData from "../../../Components/EmptyData/EmptyData";

function Shop() {
  const [orderListData, setOrderListData] = useState([]);
  const [firstOverview, setFirstOverView] = useState({});
  const [secondOverview, setSecondOverView] = useState({});
  const [thirdOverView, setThirdOverView] = useState();
  const [thirdDataDisplay, setThirdDataDisplay] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    onClickUserMainListApiCall();
    onApiCallOverView();
  }, []);

  const onApiCallOverView = async () => {
    try {
      dispatch(setLoadingTrue());
      post_api_main_overview().then((response) => {
        console.log("response", response);
        setFirstOverView(response?.data[0]?.overview);
        setSecondOverView(response?.data[1]?.salesOverview);

        const colorSet = [
          "#12B76A",
          "#2E90FA",
          "#F79009",
          "#C709F7",
          "#F7097B",
        ];

        const mappedOptions = response?.data[2]?.topFiveSellingProducts?.map(
          (item, index) => ({
            value: item.totalOrders,
            label: item.productName,
            color: colorSet[index],
          })
        );

        setThirdDataDisplay(mappedOptions);
        setThirdOverView({
          labels: mappedOptions?.map((item) => item.label),
          datasets: [
            {
              data: mappedOptions?.map((item) => item.value),
              backgroundColor: mappedOptions?.map((item) => item.color),
              hoverBackgroundColor: mappedOptions?.map((item) => item.color),
              borderWidth: 0,
            },
          ],
        });
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickUserMainListApiCall = () => {
    let param = {
      offset: 0,
      limit: 10,
    };
    try {
      dispatch(setLoadingTrue());
      getApiAllOrderList(param).then((response) => {
        setOrderListData(response.orderlist);
        dispatch(setLoadingFalse());
      });
    } catch (err) {
      dispatch(setLoadingFalse());
    }
  };

  const onClickShowMore = () => {
    const event = new CustomEvent("subTabEventListner", { detail: "/Orders" });
    window.dispatchEvent(event);
    navigate("/Orders");
  };
  console.log("firstOverviewfirstOverview", firstOverview);
  return (
    <div
      style={{
        padding: 20,
        backgroundColor: Colors.darkBlack,
      }}
    >
      <Header name={"Zigzek shop"} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "1rem",
        }}
      >
        <div className="astrologer-inner">
          <div className="frame-container">
            <div className="overview-wrapper">
              <div className="fontSize24">Overview</div>
            </div>
            <div className="frame-div">
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total orders</div>
                <div className="fontSize24">
                  {firstOverview?.totalOrdersCount}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Total revenue</div>
                <div
                  style={{ color: Colors.darkOrange }}
                  className="fontSize24"
                >
                  {firstOverview?.totalRevenue}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Net profit</div>
                <div style={{ color: Colors.darkGreen }} className="fontSize24">
                  {firstOverview?.netProfit}
                </div>
              </div>
              <div className="total-astrologers-parent">
                <div className="fontSize16">Canceled orders</div>
                <div style={{ color: Colors.darkRed }} className="fontSize24">
                  {firstOverview?.cancelOrderCount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sectionDivStyle">
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "16px",
              backgroundColor: "#25252d",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "1rem 0rem 0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0rem 1rem",
                  gap: "1rem",
                }}
              >
                <div className="contentTitle24Weight40">Overview</div>
              </div>
              <div
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  fontSize: "1rem",
                  color: "rgba(255, 255, 255, 0.5)",
                }}
              >
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Today
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: "#fff",
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹{secondOverview?.todayRevenue}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    This week
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.white,
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹{secondOverview?.thisWeekRevenue}
                  </div>
                </div>
                <div
                  style={{
                    alignSelf: "stretch",
                    position: "relative",
                    borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                    boxSizing: "border-box",
                    height: "0.06rem",
                  }}
                />
                <div
                  style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "0.5rem 1rem 0rem",
                    gap: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "500",
                    }}
                    className="lastWeekEndDiv"
                  >
                    This month
                  </div>
                  <div
                    style={{
                      position: "relative",
                      color: Colors.white,
                      fontWeight: "500",
                      fontSize: "1.5rem",
                    }}
                    className="title24"
                  >
                    ₹{secondOverview?.thisMonthRevenue}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderRadius: "16px",
              backgroundColor: Colors.mainDarkColor,
              // width: "49.38rem",
              alignSelf: "stretch",
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              padding: "1rem",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.5rem",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div
                  className="contentTitle24Weight40"
                  style={{ flex: "1", position: "relative" }}
                >
                  Top 5 selling products
                </div>
                {/* <div className="showMoreMainDiv">
                  <div className="dateTimeGreyColorShow">Show more</div>
                  <img
                    className="showMoreIcon"
                    alt=""
                    src={Icons.ic_arrow_right}
                  />
                </div> */}
              </div>
            </div>
            <div
              style={{
                alignSelf: "stretch",
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",

                fontSize: "1rem",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "12rem",
                  alignItems: "center",
                  display: "flex",
                  zIndex: 1,
                  height: "12rem",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    height: "90%",
                    width: "90%",
                    zIndex: -1,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="lastWeekEndDiv" style={{ fontWeight: "600" }}>
                    Total
                  </div>
                  <div
                    className="astrologerText"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    65826
                  </div>
                </div>
                <div
                  style={{
                    height: "90%",
                    width: "90%",
                  }}
                >
                  {thirdOverView && (
                    <Doughnut
                      options={{
                        cutout: "75%",
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                      }}
                      data={thirdOverView}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1rem",
                }}
              >
                {thirdDataDisplay?.map((item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignSelf: "stretch",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                          borderRadius: "50%",
                          backgroundColor: item?.color,
                          width: "1rem",
                          height: "1rem",
                        }}
                      />
                      <div
                        style={{
                          flex: "1",
                          color: Colors.secondary,
                          fontWeight: "400",
                          marginLeft: "8px",
                        }}
                        className="lastWeekEndDiv"
                      >
                        {item?.label}
                      </div>
                      <div
                        style={{ fontWeight: "400" }}
                        className="lastWeekEndDiv"
                      >
                        ₹{item?.value}
                      </div>
                    </div>
                  );
                })}

                {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      backgroundColor: Colors.darkBlue,
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      color: Colors.secondary,
                      fontWeight: "400",
                      marginLeft: "8px",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Mangal kawach
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                    
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Shree mahamrutyunjay yantra
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                      
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    5 ratti blue sapphire (neelam)
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    alignSelf: "stretch",
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      borderRadius: "50%",
                       
                      width: "1rem",
                      height: "1rem",
                    }}
                  />
                  <div
                    style={{
                      flex: "1",
                      marginLeft: "8px",
                      color: Colors.secondary,
                      fontWeight: "400",
                    }}
                    className="lastWeekEndDiv"
                  >
                    Gemstone
                  </div>
                  <div style={{ fontWeight: "400" }} className="lastWeekEndDiv">
                    5486
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            borderRadius: "16px",
            backgroundColor: Colors.mainDarkColor,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            padding: "1rem",
            gap: "1rem",
            flex: 1,
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "0.5rem",
              }}
            >
              <div className="astrologerText">New orders</div>
            </div>
            <div
              onClick={() => onClickShowMore()}
              style={{ cursor: "pointer" }}
              className="showMoreMainDiv"
            >
              <div className="dateTimeGreyColorShow">Show more</div>
              <img className="showMoreIcon" alt="" src={Icons.ic_arrow_right} />
            </div>
          </div>
          <div
            style={{
              alignSelf: "stretch",
              flex: "1",
              borderRadius: "8px",
              border: Colors.darkBorderColor,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "1rem",
            }}
          >
            <div
              style={{
                alignSelf: "stretch",
                flex: 1,
                backgroundColor: Colors.borderColor,
                borderBottom: Colors.darkBorderColor,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                color: "rgba(255, 255, 255, 0.5)",
                justifyContent: "space-between",
                padding: "1rem",
              }}
            >
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "22%",
                  fontWeight: 600,
                }}
              >
                Order ID
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "25%",
                  fontWeight: 600,
                }}
              >
                Customer
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "13%",
                  fontWeight: 600,
                }}
              >
                SKU Number
              </div>
              <div
                className="lastWeekEndDivGreyColor"
                style={{
                  width: "20%",
                  fontWeight: 600,
                }}
              >
                Date & Time
              </div>
              <div
                style={{
                  width: "10%",
                  fontWeight: 600,
                }}
                className="lastWeekEndDivGreyColor"
              >
                Amount
              </div>
              <div
                style={{
                  width: "10%",
                  fontWeight: 600,
                }}
                className="lastWeekEndDivGreyColor"
              >
                Status
              </div>
            </div>
            <div
              style={{
                maxHeight: "480px",
              }}
              className="scrollable-container"
            >
              {orderListData.map((item, index) => (
                <ShopOrderList
                  item={item}
                  key={index}
                  isLast={index === orderListData.length - 1}
                />
              ))}
            </div>
          </div>
          {orderListData.length <= 0 &&  <div
      style={{
        alignItems: "center",
        display: "flex",
        flex: 1,
        marginTop: '20px',
        marginBottom: '20px',
        // border:"1px solid red",
        alignSelf: "stretch",
        color: "#FFF",
        fontWeight: 800,
        justifyContent: "center",
      }}
    >
      No Data Found
    </div>}

        </div>
        
      </div>
    </div>
  );
}

export default Shop;
