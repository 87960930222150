import React from "react";
import Icons from "../../../../Constants/Icons";
import Colors from "../../../../Constants/Colors";
import StatusButton from "../../../../Components/StatusButton/StatusButton";
import moment from "moment";

function Usertransaction({
  transactionID,
  User,
  Offer,
  dateTime,
  Transactionamount,
  amount,
  status,
  data,
  amountSort,
  UserHide,TaxAmooutSort,
  OfferHide,
  TransactionamountHide,
  TaxamountHide,
  Taxamount,
  handleScroll,commentsContainerRef
}) {
  
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: 1,
        borderRadius: "8px",
        border: Colors.darkBorderColor,
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1rem",
      }}
    >
      <div
        style={{
          alignSelf: "stretch",
          flex: "1",
          backgroundColor: Colors.borderColor,
          borderBottom: Colors.darkBorderColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.8rem",
        }}
      >
        <div style={{ width: "13%" }} className="contentName">
          {transactionID}
        </div>
        {!UserHide && (
          <div style={{ width: "15%" }} className="contentName">
            {User}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "15%",
          }}
          className="contentName"
        >
          {dateTime}
        </div>
        {!OfferHide && (
          <div style={{ width: "15%" }} className="contentName">
            {Offer}
          </div>
        )}
        <div
          onClick={()=>amountSort("Tra.Amount")}
          style={{
            width: "12%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {!TransactionamountHide && <div>{Transactionamount}</div>}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
              
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>

        <div
          onClick={()=>amountSort("Tax")}
          style={{
            width: "15%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {!TaxamountHide && <div>{Taxamount}</div>}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>

        <div
          onClick={()=>amountSort("TotalAmount")}
          style={{
            width: "13%",
            display: "flex",
            cursor: "pointer",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="contentName"
            style={{
              fontWeight: "600",
            }}
          >
            {amount}
          </div>
          <img
            style={{
              position: "relative",
              width: "1.5rem",
              height: "1.5rem",
              overflow: "hidden",
              flexShrink: "0",
            }}
            alt=""
            src={Icons.ic_shorting}
          />
        </div>
        <div style={{ width: "10%" }} className="contentName">
          {status}
        </div>
      </div>

      <div style={{ maxHeight: "500px" }} ref={commentsContainerRef} onScroll={handleScroll} className="scrollable-container">
        {data.map((transaction, index) => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: 1,
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                padding: "0.8rem",
                alignSelf: "stretch",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "13%",
                  wordBreak: "break-all",
                }}
              >
              <div style={{
                  width: "85%",
                  wordBreak: "break-all",
                }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "0.5rem",
                    alignSelf: "stretch",
                  }}
                >
                  <div  className="fontSize18Weight400">
                    {transaction?.transactionId}
                  </div>
                  <img
                    style={{ cursor: "pointer", Top: "5px" }}
                    onClick={() => navigator.clipboard.writeText(transaction?.transactionId)}
                    className="icon_copy"
                    alt=""
                    src={Icons.ic_copy}
                  />
                </div>
                <div style={{ color: Colors.secondary, fontSize: "14px" }}>
                  {transaction.add_wallet_paymentType_display}
                </div>
              </div>
              </div>

              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "0.5rem",
                }}
              >
                <div style={{}} className="indian-man-smiling-astrologer">
                  <img
                    style={{
                      position: "relative",
                      width: "2.63rem",
                      height: "2.63rem",
                    }}
                    alt=""
                    src={transaction?.userImage ?? Icons.ic_zigzek_logo}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div className="fontSize18Weight400">{transaction.userName}</div>
                </div>
              </div>

              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">
                  {moment(transaction.date_time).format("DD-MM-YYYY")} <br />
                  {moment(transaction.date_time).format("hh:mm:ss a")}
                </div>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400">
                  {transaction.Offer}
                  <div style={{ color: Colors.secondary }}>
                    {transaction.Offercode}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "12%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400 usrmaxb">
                ₹{transaction.add_wallet_amount}
                  <br />
                  <div style={{ color: Colors.darkGreen }}>
                  ₹{transaction.extr_percentage_amount}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400 usrmaxa">
                ₹{transaction.add_wallet_gst_amount}
                </div>
              </div>
              <div
                style={{
                  width: "13%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div className="fontSize18Weight400 usrmax">
                ₹{transaction.totalAmount}
                </div>
              </div>

              <StatusButton
                style={{
                  width: "10%",
                  backgroundColor:
                  transaction.transaction_status === "success"
                      ? Colors.mainGreen01
                      : transaction.transaction_status === "failed"
                      ? Colors.mainRed01
                      : Colors.mainOrange01,
                  borderColor:
                  transaction.transaction_status === "success"
                      ? Colors.darkGreen
                      : transaction.transaction_status === "failed"
                      ? Colors.darkRed
                      : Colors.darkOrange,
                  color:
                    transaction.transaction_status === "success"
                      ? Colors.darkGreen
                      : transaction.transaction_status === "failed"
                      ? Colors.darkRed
                      : Colors.darkOrange,
                }}
              >
                {transaction.transaction_status}
              </StatusButton>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Usertransaction;
