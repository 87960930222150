import React from "react";
import StatusButton from "../../../../Components/StatusButton/StatusButton";
import Colors from "../../../../Constants/Colors";
import Icons from "../../../../Constants/Icons";
import moment from "moment";

function ShopOrderList({
  item,
  skuNumber,
  dateTime,
  amount,
  status,
  isLast,
}) {
  const onClickIdCopy = () => {
    navigator.clipboard.writeText("");
  };
  return (
    <div
      style={{
        alignSelf: "stretch",
        flex: "1",
        display: "flex",
        borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem",
        borderBottomWidth: isLast ? 0 : 1,
      }}
    >
      <div
        style={{
          width: "22%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
          fontSize: "0.88rem",
          color: Colors.greyColor50,
        }}
      >
        <div
          style={{
            position: "relative",
            display: "inline-block",
            fontFamily: "Poppins-Regular",
            fontSize: "0.8rem",
            fontWeight: 600,
            color: "#fff",
          }}
        >
          {item?.orderId}
        </div>
        <img
          style={{ cursor: "pointer" }}
          onClick={() =>  navigator.clipboard.writeText(item?.orderId)}
          className="icon_copy"
          alt=""
          src={Icons.ic_copy}
        />
      </div>
      <div
        style={{
          width: "25%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "0.5rem",
        }}
      >
        <div style={{}} className="indian-man-smiling-astrologer">
          <img
            style={{
              position: "relative",
              width: "2.63rem",
              height: "2.63rem",
            }}
            alt=""
            src={item.userImage}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <div className="fontSize18Weight400">{item.userName}</div>
          <div
            style={{
              color: Colors.greyColor50,
            }}
            className="fontSize18Weight400"
          >
            {item?.userId}
          </div>
        </div>
        </div>
        <div
          style={{
            color: Colors.darkBlue,
            width: "13%",
          }}
          className="fontSize18Weight400"
        >
          {skuNumber}
        </div>
        <div
          style={{
            width: "20%",
          }}
          className="fontSize18Weight400"
        >
             {moment(item?.order_date_and_time).format("DD-MM-YYYY")} <br />
             {moment(item?.order_date_and_time).format("hh:mm a")}
        </div>
        <div
          style={{
            width: "10%",
          }}
          className="fontSize18Weight400"
        >
          {"₹"+item.amount}
        </div>
        <StatusButton
              style={{
                width: "10%",
                backgroundColor:
                item?.orderStatus === "New"
                    ? Colors.mainBlue01
                    : item?.orderStatus === "Canceled"
                    ? Colors.mainRed01
                    : Colors.mainOrange01,
                borderColor:
                item?.orderStatus === "New"
                    ? Colors.darkBlue
                    : item?.orderStatus === "Canceled"
                    ? Colors.darkRed
                    : Colors.darkOrange,
                color:
                item?.orderStatus === "New"
                    ? Colors.darkBlue
                    : item?.orderStatus === "Canceled"
                    ? Colors.darkRed
                    : Colors.darkOrange,
              }}
            >
              {item?.orderStatus}
            </StatusButton>
      </div>
 
  );
}

export default ShopOrderList;
