const Icons = {
  ic_notifications: require("../Assets/Icons/ic_notifications.png"),
  ic_down: require("../Assets/Icons/ic_down.png"),
  ic_arrow_right: require("../Assets/Icons/ic_arrow_right.png"),
  ic_default_user: require("../Assets/Icons/ic_default_user.png"),
  ic_copy: require("../Assets/Icons/ic_copy.png"),
  ic_refresh: require("../Assets/Icons/ic_refresh.png"),
  ic_home: require("../Assets/Icons/ic_home.png"),
  ic_astrologers: require("../Assets/Icons/ic_astrologers.png"),
  ic_sessions: require("../Assets/Icons/ic_sessions.png"),
  ic_shop: require("../Assets/Icons/ic_shop.png"),
  ic_user: require("../Assets/Icons/ic_user.png"),
  ic_customer_support: require("../Assets/Icons/ic_customer_support.png"),
  ic_coupon: require("../Assets/Icons/ic_coupon.png"),
  ic_transaction: require("../Assets/Icons/ic_transaction.png"),
  ic_regstration: require("../Assets/Icons/ic_regstration.png"),
  ic_grey_home: require("../Assets/Icons/ic_grey_home.png"),

  ic_shop_white: require("../Assets/Icons/ic_shop_white.png"),
  ic_user_white: require("../Assets/Icons/ic_user_white.png"),
  ic_cpupon_white: require("../Assets/Icons/ic_cpupon_white.png"),
  ic_customer_white: require("../Assets/Icons/ic_customer_white.png"),
  ic_sessions_white: require("../Assets/Icons/ic_sessions_white.png"),
  ic_astrologer_white: require("../Assets/Icons/ic_astrologer_white.png"),
  ic_regstration_white: require("../Assets/Icons/ic_regstration_white.png"),
  ic_transaction_white: require("../Assets/Icons/ic_transaction_white.png"),
  ic_search: require("../Assets/Icons/ic_search.png"),
  ic_shorting: require("../Assets/Icons/ic_shorting.png"),
  ic_star: require("../Assets/Icons/ic_star.png"),
  ic_white_right_arrow: require("../Assets/Icons/ic_white_right_arrow.png"),
  ic_white_left_arrow: require("../Assets/Icons/ic_white_left_arrow.png"),

  ic_sms: require("../Assets/Icons/ic_sms.png"),
  ic_calendar: require("../Assets/Icons/ic_calendar.png"),
  ic_call: require("../Assets/Icons/ic_call.png"),
  ic_location: require("../Assets/Icons/ic_location.png"),
  ic_wallet: require("../Assets/Icons/ic_wallet.png"),
  ic_white_wallet: require("../Assets/Icons/ic_white_wallet.png"),
  ic_close: require("../Assets/Icons/ic_close.png"),
  ic_more: require("../Assets/Icons/ic_more.png"),

  ic_blue_messages: require("../Assets/Icons/ic_blue_messages.png"),
  ic_orange_duration: require("../Assets/Icons/ic_orange_duration.png"),
  ic_green_eye: require("../Assets/Icons/ic_green_eye.png"),
  ic_watch: require("../Assets/Icons/ic_watch.png"),
  ic_grey_clock: require("../Assets/Icons/ic_grey_clock.png"),
  ic_choclate: require("../Assets/Icons/ic_choclate.png"),
  ic_emoji_default: require("../Assets/Icons/ic_emoji_default.png"),
  ic_add: require("../Assets/Icons/ic_add.png"),
  ic_grey_calendar: require("../Assets/Icons/ic_grey_calendar.png"),
  ic_grey_watch: require("../Assets/Icons/ic_grey_watch.png"),
  ic_grey_down: require("../Assets/Icons/ic_grey_down.png"),
  ic_send_message: require("../Assets/Icons/ic_send_message.png"),
  ic_man_default: require("../Assets/Icons/ic_man_default.png"),
  ic_man_large_default: require("../Assets/Icons/ic_man_large_default.png"),

  ic_volumehigh: require("../Assets/Icons/ic_volumehigh.png"),
  ic_forwardseconds: require("../Assets/Icons/ic_forwardseconds.png"),
  ic_backwardseconds: require("../Assets/Icons/ic_backwardseconds.png"),
  ic_play_pause: require("../Assets/Icons/ic_play_pause.png"),
  ic_wave_sound: require("../Assets/Icons/ic_wave_sound.png"),
  ic_audio_pause: require("../Assets/Icons/ic_audio_pause.png"),
  ic_wave_stop: require("../Assets/Icons/ic_wave_stop.png"),
  hide_password: require("../Assets/Icons/hide_password.png"),
  show_password: require("../Assets/Icons/show_password.png"),
  ic_upload_photo: require("../Assets/Icons/ic_upload_photo.png"),
  ic_edit_info: require("../Assets/Icons/ic_edit_info.png"),
  ic_zigzek_logo: require("../Assets/Icons/ic_zigzek_logo.png"),
  ic_logout: require("../Assets/Icons/ic_logout.png"),
  ic_devices: require("../Assets/Icons/ic_devices.png"),
  ic_red_close: require("../Assets/Icons/ic_red_close.png"),
  ic_password_successufly: require("../Assets/Icons/ic_password_successufly.png"),
  ic_white_notification: require("../Assets/Icons/ic_white_notification.png"),
  ic_notification_grey: require("../Assets/Icons/ic_notification_grey.png"),
  ic_link: require("../Assets/Icons/ic_link.png"),
  ic_linky: require("../Assets/Icons/y.png"),
  ic_linkx: require("../Assets/Icons/x.png"),
  Fileicon: require("../Assets/Icons/flie.png"),
  Vector: require("../Assets/Icons/Vector.png"),
  ic_trash: require("../Assets/Icons/ic_trash.png"),

  ic_banner_grey: require("../Assets/Icons/ic_banner_grey.png"),
  ic_banner_white: require("../Assets/Icons/ic_banner_white.png"),
  ic_white_file: require("../Assets/Icons/ic_white_file.png"),
  ic_wave_stop_without_back: require("../Assets/Icons/ic_wave_stop_without_back.png"),

};
export default Icons;
